import React, { useEffect, useState } from "react";
import Page_titles from "../../components/Page_titles";
import { useSelector } from "react-redux";
import { user_purchase_history_service } from "../../services/user";
import { nanoid } from "@reduxjs/toolkit";
import Hero from "../../components/Hero";

const Date_rander_component = ({ header_name, details_data }) => {
  const data_format_generate = (data_arr) => {
    // console.log(data_arr);
    return data_arr.map((d, n) => {
      const code = (
        <tr key={(n = "key")}>
          <td>
            <div style={{ textAlign: "left" }}>{d.key}</div>
          </td>
          <td>
            <div style={{ textAlign: "left" }}>{d.value}</div>
          </td>
        </tr>
      );
      return code;
    });
  };

  return (
    <div className="card" style={{ minHeight: "100px" }}>
      <div className="card-header">
        <h5>{header_name}</h5>
      </div>
      <div className="card-body">
        <div className="table-responsive active-projects style-1">
          <div className="tbl-caption"></div>
          <table className="table">
            <tbody>{data_format_generate(details_data)}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const Box = ({ header_name, children }) => {


  return (
    <div className="card" style={{ minHeight: "100px" }}>
      <div className="card-header">
        <h5>{header_name}</h5>
      </div>
      <div className="card-body">
        {children}
      </div>
    </div>
  );
};




const User_details = () => {
  const [user_details_data, SetUser_details_data] = useState([]);

  const user_details = useSelector((state) => state.user.user_details);
  const enrolled_plan_details = useSelector(
    (state) => state.user.enrolled_plan
  );
  useEffect(() => {
    // let user_details_data=
    // console.log(user_details)
    SetUser_details_data([
      { key: "Name", value: user_details.name || "not loaded" },
      { key: "Email", value: user_details.email || "not loaded" },
      {
        key: "Your Reffral Code",
        value: user_details.user_referral_code || "not loaded",
      },
      { key: "Mobile", value: user_details.mobile || "not loaded" },
      { key: "City", value: user_details.city || "not loaded" },
      { key: "State", value: user_details.state || "not loaded" },
      { key: "Zipcode", value: user_details.zipcode },
     
    ]);
  }, [user_details]);
  // console.log(user_details);
  return (
    <Date_rander_component
      header_name={"User Details"}
      details_data={user_details_data}
    />
  );
};
const Purchase_details = ({ user_details_state }) => {
  const [data_arr, setData_arr] = useState([]);
  const [details_data, SetDetails_data] = useState({
    is_loaded: false,
    is_render: false,
    msg: "",
    data_arr: [],
  });

  const user_details = user_details_state;
  const is_user_enrolled = user_details?.is_enrolled;
  const Purchase_details = user_details;
  // console.log(user_details);
  const enrolled_plan_details = useSelector(
    (state) => state.user.enrolled_plan
  );

  useEffect(() => {
    const is_user_enrolled = user_details?.is_enrolled;
    if (is_user_enrolled == true) {
      // console.log(user_details);
      const purchase_details =
        user_details?.payments_details_junction?.purchase_details;
      const transaction_details =
        user_details?.payments_details_junction?.transaction;
      const enrolled_plan_details = user_details?.enrolled_plan;
      if (transaction_details?.is_cash_payment == true) {
        setData_arr([
          {
            key: "Plan Name",
            value: enrolled_plan_details?.plan_name || "not loaded",
          },
          {
            key: "Plan Price",
            value: enrolled_plan_details?.price || "not loaded",
          },
          {
            key: "Purchased Price",
            value:
              purchase_details?.discount_calc_details?.final_plan_price ||
              "not loaded",
          },
          {
            key: "Commission",
            value:
              enrolled_plan_details?.commision_percentage + "%" || "not loaded",
          },
          {
            key: "Transction Date",
            value: transaction_details?.createdAt || "not loaded",
          },
          {
            key: "Transction ID",
            value: transaction_details?._id || "not loaded",
          },
          {
            key: "Transction Date",
            value: transaction_details?.payment_method || "not loaded",
          },
        ]);
      } else {
        setData_arr([
          {
            key: "Plan Name",
            value: enrolled_plan_details?.plan_name || "not loaded",
          },
          {
            key: "Plan Price",
            value: enrolled_plan_details?.price || "not loaded",
          },
          {
            key: "Purchased Price",
            value:
              purchase_details?.discount_calc_details?.final_plan_price ||
              "not loaded",
          },
          {
            key: "Commission",
            value:
              enrolled_plan_details?.commision_percentage + "%" || "not loaded",
          },
          {
            key: "Transction Date",
            value: transaction_details?.createdAt || "not loaded",
          },
          {
            key: "Transction ID",
            value: transaction_details?._id || "not loaded",
          },
          {
            key: "Transction Date",
            value: transaction_details?.payment_method || "not loaded",
          },
          {
            key: "Transction ID",
            value: transaction_details?._id || "not loaded",
          },
          {
            key: "Razorpay Payments ID",
            value: transaction_details?.payment_id || "not loaded",
          },
        ]);
      }
    }
    // let user_details_data=
  }, [user_details]);
  // console.log(user_details);
  return (<>
  {is_user_enrolled==true?
    <Date_rander_component
      header_name={"Purchase Details"}
      is_loaded={details_data.is_loaded}
      details_data={data_arr}
    />
    :<div className="card">
     <div className="card-header" >Purchase Details</div>
     <div className="card-body"><span>Purchase Details data not avaliable</span></div>
    </div>
  }
    </>
  );
};
const Purchase_details_history = ({ user_details_state }) => {
  // console.log(["Purchase_details_history"]);
  const [all_data_arr, setAll_data_arr] = useState([]);
  const [details_data, SetDetails_data] = useState({
    is_loaded: false,
    is_render: false,
    msg: "",
    data_arr: [],
  });

  const user_details = user_details_state;
  const is_user_enrolled = user_details?.is_enrolled;
  const Purchase_details = user_details;
  // console.log(user_details);
  const enrolled_plan_details = useSelector(
    (state) => state.user.enrolled_plan
  );

  useEffect(() => {
    const api_handler = async () => {
      const resp = await user_purchase_history_service();
      // console.log(data);
    if (is_user_enrolled == true) {
      // arranging data in require format
      const data=resp?.data?.data
      // console.log(data)
     const created_arr= data.map((d)=>{
      // console.log(d)
        const purchase_details =d?.purchase_details;
        const transaction_details =d?.transaction;
        const plan_details = d?.plan;
        let arr=[];
   
        if (transaction_details?.is_cash_payment == true) {
           arr=[
            {
              key: "Plan Name",
              value: plan_details?.plan_name || "not loaded",
            },
            {
              key: "Plan Price",
              value: plan_details?.price || "not loaded",
            },
            {
              key: "Purchased Price",
              value:
                purchase_details?.discount_calc_details?.final_plan_price ||
                "not loaded",
            },
            {
              key: "Commission",
              value:
              plan_details?.commision_percentage + "%" || "not loaded",
            },
            {
              key: "Transction Date",
              value: transaction_details?.createdAt || "not loaded",
            },
            {
              key: "Transction ID",
              value: transaction_details?._id || "not loaded",
            },
            {
              key: "Transction Date",
              value: transaction_details?.payment_method || "not loaded",
            },
          ];
          
        } else {
           arr=[
            {
              key: "Plan Name",
              value: plan_details?.plan_name || "not loaded",
            },
            {
              key: "Plan Price",
              value: plan_details?.price || "not loaded",
            },
            {
              key: "Purchased Price",
              value:
                purchase_details?.discount_calc_details?.final_plan_price ||
                "not loaded",
            },
            {
              key: "Commission",
              value:
              plan_details?.commision_percentage + "%" || "not loaded",
            },
            {
              key: "Transction Date",
              value: transaction_details?.createdAt || "not loaded",
            },
            {
              key: "Transction ID",
              value: transaction_details?._id || "not loaded",
            },
            {
              key: "Transction Date",
              value: transaction_details?.payment_method || "not loaded",
            },
            {
              key: "Transction ID",
              value: transaction_details?._id || "not loaded",
            },
            {
              key: "Razorpay Payments ID",
              value: transaction_details?.payment_id || "not loaded",
            },
          ];
        }
        return arr;
      })
      setAll_data_arr(created_arr)
    //  console.log(created_arr,"created_arr,created_arr,created_arr")
    }
    else{
      SetDetails_data({ 
        is_loaded: false,
        is_render: false,
        msg: "data not loaded",
        data_arr: [],})
    }
  };
  api_handler();
  }, [user_details]);
  // console.log(user_details);
  return (
    <>
      <div key={nanoid()} className="row">

        {is_user_enrolled==true?
        Array.isArray(all_data_arr)==true?
        all_data_arr.map((data_arr)=>{
          return  <div className="col-xl-6">
                    <div>
                      <Date_rander_component
                        header_name={"Purchase Details"}
                        is_loaded={true}
                        details_data={data_arr}
                      />
                    </div>
                  </div>
        })

        :"no data loaded"
        :
        "no data avaliable"
        }
       
        
       
      </div>
    </>
  );
};
const Profile_setting=()=>{

return <div>



</div>
}
function My_account(props) {
  const [show_purchase_history,setShow_purchase_history]=useState(false)
  const user_details_state = useSelector((state) => state.user.user_details);

  return (
    <>
      <Page_titles name={props.name} />
      <div className="container-fluid">
        <div className="row">
        <div className="col-xl-12">
            {/* <Date_rander_component header_name={"User Details"}/> */}
            {/* <User_details /> */}
            <Box header_name={'Profile Setting'}>
             {/* <Profile_setting/>
              */}
              {<Hero is_editable={true} />}
            </Box>
            </div>
          <div className="col-xl-6">
            {/* <Date_rander_component header_name={"User Details"}/> */}
            <User_details />
          </div>
          <div className="col-xl-6">
            <Purchase_details user_details_state={user_details_state} />
            {/* <Date_rander_component header_name={"User Details"}/> */}
          </div>
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header"><h4>Purchase History</h4>
              <div><button onClick={()=>{setShow_purchase_history(!show_purchase_history)}} className="btn btn-primary">{show_purchase_history==true?"close":"open"}</button></div>
              </div>
              {show_purchase_history &&  <div className="card-body">
            <Purchase_details_history user_details_state={user_details_state} />
            </div>}
             
            {/* <Date_rander_component header_name={"User Details"}/> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default My_account;
