import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import indian_states from "../JSON_data/indian_states";
import { signup_service } from "../services/user";
import { login } from "../store/authSlice";
import { useDispatch } from "react-redux";
import { add_kyc_service, kyc_upload_service } from "../services/kyc";
import { add_user_details, update_kyc_status } from "../store/userSlice";
import Msg from "./Msg";
import Msg_display from "./Msg_display";

const Kyc_forms = (props) => {
  const [loader,setLoader]=useState({status:"",msg:""});
  const [file,setFile]=useState()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [bank_document_upload_data,setBank_document_upload_data]=useState("")
  const [adhaar_card_upload_data,setAdhaar_card_upload_data]=useState("")
  const [pan_card_upload_data,setPan_card_upload_data]=useState("")
  const [profile_image_upload_data,setProfile_image_upload_data]=useState("")
  const [type_of_bank_document_upload, setType_of_bank_document_upload] =
    useState("");

  const is_empty_check = (...args) => {
    // console.log(args);
    let is_empty;
    args.map((d) => {
      // console.log(d);
      if (d === "" || d === null || d === undefined) {
        is_empty = true;
      }
    });
    // console.log({ is_empty });
    if (is_empty === true) {
      return true;
    } else {
      return false;
    }
  };

  const name_value_ref = useRef();
  const adhar_card_number_ref = useRef();
  const pan_card_number_ref = useRef();
  const adress_ref = useRef();
  const bank_name_ref = useRef();
  const bank_account_no_ref = useRef();
  const bank_ifsc_code_ref = useRef();
  const formData = new FormData();



  const signup_handler = async (a) => {
    a.preventDefault();
    try {
      const name_value = name_value_ref.current.value;
      const adhar_card_number = adhar_card_number_ref.current.value;
      const pan_card_number = pan_card_number_ref.current.value;
      const adress = adress_ref.current.value;
      const bank_name_value = bank_name_ref.current.value;
      const bank_account_no_value = bank_account_no_ref.current.value;
      const bank_ifsc_code_value = bank_ifsc_code_ref.current.value;

      // upload refs

     
      //  if(adhar_card_number.length!=12){
      //   alert("adhar_card card number can be 12 digit")
      //   return
      //  }
      setLoader({status:"null",msg:""})

      // cheking user interd data
     
      const is_empty = is_empty_check(
        name_value,
        adhar_card_number,
        pan_card_number,
        adress,
        bank_name_value,
        bank_account_no_value,
        bank_ifsc_code_value,
        adhaar_card_upload_data,
        pan_card_upload_data,
        profile_image_upload_data,
        bank_document_upload_data,

      );
      // checking is any field is empty
      if(is_empty==true){
          alert("one of field is empty")
          return
      }
 alert("ok")
      formData.append("adhardcard",adhaar_card_upload_data)
      formData.append("pancard",pan_card_upload_data)
      formData.append("profile_image",profile_image_upload_data)
      formData.append("bank_document",bank_document_upload_data)

      setLoader({status:null,msg:"submitted"})
      // console.log(formData)
      const resp1 = await add_kyc_service({
        name: name_value,
        adhar_card_number,
        pan_card_number,
        adress,
        bank_name: bank_name_value,
        bank_account_no: bank_account_no_value,
        bank_ifsc_code: bank_ifsc_code_value,
        upload_type_of_document: bank_name_value,
        });
   
  
      if (resp1.status == 201) {
        // alert("KYC submitted sucessfully");
      //  setLoader({status:null,msg:"submitted"})
     const resp = await kyc_upload_service({
        formData
        })
        dispatch(update_kyc_status());
        setBank_document_upload_data('')
        setAdhaar_card_upload_data('')
        setPan_card_upload_data('')
        setProfile_image_upload_data('')
      // console.log(resp);
      if(resp.status>=200 && resp.status<300){
        setLoader({status:true,msg:"submitted successfully"})
      }
      else{
        setLoader({status:false,msg:"uploading failed,try again"})
      }
      }
      else{
        setLoader({status:false,msg:"Submission failed,try again"})
      }
      
      // console.log(resp1)
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <h3 className="text-center">{props.name}</h3>

      <>
        <div className="col-xl-6 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="basic-form">
                <form>
                  <div className="row">
                    <div className="mb-3 col-md-6">
                      <label className="form-label">Name</label>
                      <input
                        ref={name_value_ref}
                        type="text"
                        className="form-control"
                        placeholder="Name on identity card"
                      />
                    </div>
                  
                    <div className="mb-3 col-md-6">
                      <label className="form-label">Adhar Card Number</label>
                      <input
                        ref={adhar_card_number_ref}
                        type="number"
                        className="form-control"
                        placeholder="Adhar Card"
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label">Pan Card</label>
                      <input
                        ref={pan_card_number_ref}
                        type="text"
                        className="form-control"
                        placeholder="Pan Card"
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label>Adress on Adhar Card</label>
                      <input
                        type="text"
                        placeholder="Adress"
                        ref={adress_ref}
                        className="form-control"
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label>Bank Name</label>
                      <input
                        ref={bank_name_ref}
                        type="text"
                        placeholder="Bank Name"
                        className="form-control"
                      />
                    </div>

                    <div className="mb-3 col-md-6">
                      <label>Bank Account No.</label>
                      <input
                        ref={bank_account_no_ref}
                        type="number"
                        placeholder="Account No."
                        className="form-control"
                      />
                    </div>

                    <div className="mb-3 col-md-6">
                      <label>Bank Ifsc Code</label>
                      <input
                        ref={bank_ifsc_code_ref}
                        type="text"
                        placeholder="Bank Ifsc code"
                        className="form-control"
                      />
                    </div>
                    {/* choose type of upload */}
                    <div className="mb-3 col-md-6">
                      <label>Select Bank Releted Document Type</label>
                      <select name="" id="" className="form-control" >
                        <option value="" key="0" selected>
                          Chosee . .
                        </option>
                        <option value="" key="1">
                          bank Statement
                        </option>
                        <option value="" key="2">
                          PaySlip
                        </option>
                        <option value="" key="3">
                          cancel Cheque
                        </option>
                        <option value="" key="3">
                          other
                        </option>
                      </select>
                    </div>

                    <div className="mb-3 col-md-6">
                      <label>Upload Selected Bank Document </label>
                      <input
                     
                        id="file"
                        type="file"
                        onChange={(e) => {
                          setBank_document_upload_data(e.target.files[0]);
                        }}
                        placeholder={type_of_bank_document_upload}
                        className="form-control"
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label>Upload Profile Image </label>
                      <input
                      
                        id="file"
                        type="file"
                        onChange={(e) => {
                          // console.log(e.target.files[0]);
                          setProfile_image_upload_data(e.target.files[0]);
                        }}
                        placeholder="Profile image"
                        className="form-control"
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label>Upload Adhard Card </label>
                      <input
                        // ref={adharcard_upload_ref}
                        id="file"
                        type="file"
                        onChange={(e) => {
                          // console.log(e.target.files[0]);
                          setAdhaar_card_upload_data(e.target.files[0]);
                        }}
                        placeholder="Adhaar Card Back and Front in one page"
                        className="form-control"
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label>Upload Pan Card</label>
                      <input
                        id="file"
                        type="file"
                        onChange={(e) => {
                          // console.log(e.target.files[0]);
                          setPan_card_upload_data(e.target.files[0]);
                        }}
                        placeholder="Pancard Image"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="row">
                  <div className="mb-3 col-md-2 w-100">
                    <Msg_display status={loader.status} msg={loader.msg}/>
                     </div>
                    <div className="mb-3 col-md-2 w-100">
                      <button
                        type="submit"
                        onClick={signup_handler}
                        className="btn btn-primary w-100"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Kyc_forms;
