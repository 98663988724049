import React, { useEffect, useRef, useState } from 'react'
import { banner_upload, commission_payments_details, get_image, profile_image_upload } from '../services/user';
import { useSelector } from 'react-redux';

function HeroDesktop({ is_editable }) {
  const hidden_profile_image_input_ref = useRef(null);
  const hidden_banner_image_file_input_ref = useRef(null);

  const [profile_img_source, setProfile_image_source] = useState("")
  const [banner_img_source, setBanner_image_source] = useState("")
  const user_details = useSelector((state) => state.user.user_details);
  const {name}=user_details
  useEffect(() => {
// console.log(user_details)
const {profile_image,banner_image}=user_details;
console.log(profile_image,banner_image);
const api=async()=>{
  const {profile_image,banner_image}=user_details;
  const resp=await get_image(profile_image);
  console.log(resp)
  let url = URL.createObjectURL(resp.data)
  console.log(url)
  setProfile_image_source(url);
  const resp_banner=await get_image(banner_image);
  let url2 = URL.createObjectURL(resp_banner.data);
  setBanner_image_source(url2);
  console.log(resp)
}
api()
  }, [])


  async function handleChange_profile_image(e) {
    try {
 

    let url = URL.createObjectURL(e.target.files[0])

    setProfile_image_source(url);
    const formData = new FormData();
    formData.append("image", e.target.files[0]);

    const resp = await profile_image_upload(formData);

    alert('Profile image changed')

  
      
    } catch (error) {
      alert('please try again') 
    }
  }
  async function handleChange_banner_image(e) {
    try {
      
      let url = URL.createObjectURL(e.target.files[0]);

      setBanner_image_source(url);
      const formData = new FormData();
      formData.append("image", e.target.files[0]);
      const resp = await banner_upload(formData);
 
      alert('banner changed')

    } catch (error) {
      alert('please try again')
      console.log(error)
    }


    // setImage_source(URL.createObjectURL(e.target.files[0]));
  }

  function handleChange_banner_btn(e) {
    hidden_banner_image_file_input_ref.current.click();
  }

  function handleChange_profile_btn(e) {
    hidden_profile_image_input_ref.current.click();
  }

const [temp_image, settemp_image] = useState();
  const image_handler=async()=>{
try {
  const {profile_image,banner_image}=user_details;
  const resp=await get_image(profile_image);
  console.log(resp)
  let url = URL.createObjectURL(resp.data)
  console.log(url)
  setProfile_image_source(url);
} catch (error) {
  console.log(error)
}
  }
  
  window.addEventListener('resize',()=>{
    console.log(window.innerWidth)
  })


// level
const [level_state, setlevel_state] = useState("Starter");

useEffect(() => {
  const api_handler = async () => {
    const resp = await commission_payments_details();

    // console.log(resp);
    if (resp.status == 200) {
      let amount = 0;
      const data = resp.data.data;
      // console.log(data);
      data.map((d) => {
        amount += Number(d.commission_amount);
      });
      // setData_state(Math.round(amount));
     const recognition_arr=[
      {Stage:"Fresher",target:0},
      {Stage:"Silver",target:25000},
      {Stage:"Gold",target:50000},
      {Stage:"Pearl",target:100000},
      {Stage:"Topaz",target:250000},
      {Stage:"Emerald",target:1000000},
      {Stage:"Ruby",target:2500000},
      {Stage:"Diamond",target:5000000},
      
    
    ]


     if(amount>=recognition_arr[0].target && amount<recognition_arr[1].target ){
      setlevel_state(recognition_arr[0].Stage)
     }
     else if(amount>=recognition_arr[1].target && amount<recognition_arr[2].target ){
      setlevel_state(recognition_arr[1].Stage)
     }
     else if(amount>=recognition_arr[2].target && amount<recognition_arr[3].target ){
      setlevel_state(recognition_arr[2].Stage)
     }
     else if(amount>=recognition_arr[3].target && amount<recognition_arr[4].target ){
      setlevel_state(recognition_arr[3].Stage)
     }
     else if(amount>=recognition_arr[4].target && amount<recognition_arr[5].target ){
      setlevel_state(recognition_arr[4].Stage)
     }
     else if(amount>=recognition_arr[5].target && amount<recognition_arr[6].target ){
      setlevel_state(recognition_arr[5].Stage)
     }
     else if(amount>=recognition_arr[6].target && amount<recognition_arr[7].target ){
      setlevel_state(recognition_arr[6].Stage)
     }
     else if(amount>=recognition_arr[7].target){
      setlevel_state(recognition_arr[7].Stage)
     }
     else{
      setlevel_state("not loaded")
      
     }
    }
  };
  api_handler();
}, []);
  return (
    <div >
      {/* <button onClick={image_handler}>check</button> */}
      <div className='border px-5 border-primary border-5'
       style={{ border: "2px ", backgroundSize: "100% 100%",
         backgroundRepeat: "no-repeat",
          backgroundImage: `url(${banner_img_source})`,
           backgroundColor: "white",
            height: '20vw',
            maxHeight:"300px",
            minHeight:"150px",
            position: "relative",
            borderRadius: "15px", 
            display:"flex",
            justifyContent:"start",
            alignItems:"center",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            // marginBottom:"160px"
            
            }} >
            
        <div className='flex ' style={{display:"flex",height:"100%",alignItems:"center"
        }}>

        <div className=' border-primary lg:invisible border-5  rounded-circle border  ' 
        style={{
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
          // position: "absolute",
          //  bottom: "-170px",
          //  left:"0px",
           height:"20vw", width: "20vw",
           maxWidth:"200px",maxHeight:"200px",
          backgroundImage: `url(${profile_img_source})`,
          backgroundSize: "100% 100%", backgroundRepeat: "no-repeat"
        }}>

          {is_editable ? <div 
          className='absolute bottom-0 p-2 ' 
          style={{ position: 'absolute' }}>
            <div className='text-center'><button onClick={handleChange_profile_btn} style={{ backgroundColor: "skyblue" }} className='rounded p-2 m-1 border-0 bg-indigo-500'>change</button></div>
            <div className={` ${true == "bg-red-100" ? '' : "bg-red-100"}`}></div>
          </div> : ""}
        </div>
<div className='mx-3 p-3' 

style={{
          // boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
          // position: "absolute",
          //  bottom: "-170px",
          //  left:"0px",
          borderRadius:"30px",
      background:"linear-gradient(to right, #2F97FF, #680198)",
       display:"flex",
       alignItems:"start",
       justifyContent:"center",
       cursor:"pointer",
     
       flexDirection:"column",
         }}
           >
            <div><h1 style={{fontWeight:"bold",textTransform:"capitalize",color:"white",fontSize:"23px"}}>{name}</h1></div>
            <div>
              <h3  style={{textTransform:"capitalize",color:"white"}}>   {level_state}</h3>
         
            </div>
</div>

        </div>

        {/* change the banner */}
        {is_editable ? <div className='absolute bottom-0 p-2' style={{ position: 'absolute', right: "0" }}>
          <div><button onClick={handleChange_banner_btn} style={{ backgroundColor: "skyblue" }} className='rounded p-2 m-1 border-0 bg-indigo-500'>change </button></div>
          <div></div>
        </div> : ""}
      </div>
      {/* <img src={img_source} alt="" /> */}
      <input className="" ref={hidden_profile_image_input_ref} style={{ display: "none", backgroundColor: "red", width: "100px", border: "none" }} type="file" onInput={handleChange_profile_image} />
      <input className="" ref={hidden_banner_image_file_input_ref} style={{ display: "none", backgroundColor: "red", width: "100px", border: "none" }} type="file" onChange={handleChange_banner_image} />

    </div>

  )
}
function HeroMobile({ is_editable }) {
  const hidden_profile_image_input_ref = useRef(null);
  const hidden_banner_image_file_input_ref = useRef(null);

  const [profile_img_source, setProfile_image_source] = useState("")
  const [banner_img_source, setBanner_image_source] = useState("")
  const user_details = useSelector((state) => state.user.user_details);
const {name}=user_details
// console.log(user_details)

  useEffect(() => {
// console.log(user_details)
const {profile_image,banner_image}=user_details;

const api=async()=>{
  try {
    const {profile_image,banner_image}=user_details;
    const resp=await get_image(profile_image);
    console.log(resp)
    let url = URL.createObjectURL(resp.data)
    console.log(url)
    setProfile_image_source(url);
    const resp_banner=await get_image(banner_image);
    let url2 = URL.createObjectURL(resp_banner.data);
    setBanner_image_source(url2);
    console.log(resp)
  } catch (error) {
   
  }
 
}
api()
  }, [])


  async function handleChange_profile_image(e) {
    console.log(e.target.files);
    console.log(e.target.files[0]);

    let url = URL.createObjectURL(e.target.files[0])
    console.log(url)
    setProfile_image_source(url);
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    const resp = await profile_image_upload(formData);
    console.log(resp)
  }
  async function handleChange_banner_image(e) {
    try {
      console.log(e.target.files);
      let url = URL.createObjectURL(e.target.files[0]);
      console.log(url);
      setBanner_image_source(url);
      const formData = new FormData();
      formData.append("image", e.target.files[0]);
      const resp = await banner_upload(formData);
      console.log(resp)

    } catch (error) {
      console.log(error)
    }


    // setImage_source(URL.createObjectURL(e.target.files[0]));
  }

  function handleChange_banner_btn(e) {
    hidden_banner_image_file_input_ref.current.click();
  }

  function handleChange_profile_btn(e) {
    hidden_profile_image_input_ref.current.click();
  }

const [temp_image, settemp_image] = useState();
  const image_handler=async()=>{
try {
  const {profile_image,banner_image}=user_details;
  const resp=await get_image(profile_image);
  console.log(resp)
  let url = URL.createObjectURL(resp.data)
  console.log(url)
  setProfile_image_source(url);
} catch (error) {
  console.log(error)
}
  }
  

  const [level_state, setlevel_state] = useState("Starter");

  useEffect(() => {
    const api_handler = async () => {
      const resp = await commission_payments_details();
  
      // console.log(resp);
      if (resp.status == 200) {
        let amount = 0;
        const data = resp.data.data;
        // console.log(data);
        data.map((d) => {
          amount += Number(d.commission_amount);
        });
        // setData_state(Math.round(amount));
       const recognition_arr=[
        {Stage:"Fresher",target:0},
        {Stage:"Silver",target:25000},
        {Stage:"Gold",target:50000},
        {Stage:"Pearl",target:100000},
        {Stage:"Topaz",target:250000},
        {Stage:"Emerald",target:1000000},
        {Stage:"Ruby",target:2500000},
        {Stage:"Diamond",target:5000000},
        
      
      ]
  
  
       if(amount>=recognition_arr[0].target && amount<recognition_arr[1].target ){
        setlevel_state(recognition_arr[0].Stage)
       }
       else if(amount>=recognition_arr[1].target && amount<recognition_arr[2].target ){
        setlevel_state(recognition_arr[1].Stage)
       }
       else if(amount>=recognition_arr[2].target && amount<recognition_arr[3].target ){
        setlevel_state(recognition_arr[2].Stage)
       }
       else if(amount>=recognition_arr[3].target && amount<recognition_arr[4].target ){
        setlevel_state(recognition_arr[3].Stage)
       }
       else if(amount>=recognition_arr[4].target && amount<recognition_arr[5].target ){
        setlevel_state(recognition_arr[4].Stage)
       }
       else if(amount>=recognition_arr[5].target && amount<recognition_arr[6].target ){
        setlevel_state(recognition_arr[5].Stage)
       }
       else if(amount>=recognition_arr[6].target && amount<recognition_arr[7].target ){
        setlevel_state(recognition_arr[6].Stage)
       }
       else if(amount>=recognition_arr[7].target){
        setlevel_state(recognition_arr[7].Stage)
       }
       else{
        setlevel_state("not loaded")
        
       }
      }
    };
    api_handler();
  }, []);
  return (
    <div className=''>
      {/* <button onClick={image_handler}>check</button> */}
      <div className=' p-5 px-5 border border-primary border-5'
       style={{ border: "2px ", backgroundSize: "100% 100%",
         backgroundRepeat: "no-repeat",
          backgroundImage: `url(${banner_img_source})`,
           backgroundColor: "white",
            height: '150px',
            position: "relative",
            borderRadius: "15px", 
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            marginBottom:"150px"
            }} >
            
    <div className='p-3 '
    style={{
      borderRadius:"15px",
      background:"linear-gradient(to right, #2F97FF, #680198)",
      position: "absolute",
      bottom:"-150px",
      left:"0px",
      display:"flex",
      width:"100%"
    }}
    >
    <div className='custom-position border-primary lg:invisible border-5  rounded-circle border  ' 
        style={{
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
          minWidth:"100px",minHeight:"100px",
          backgroundImage: `url(${profile_img_source})`,
          backgroundSize: "100% 100%", backgroundRepeat: "no-repeat"
        }}>

          {is_editable ? <div 
          className='absolute bottom-0 p-2 ' 
          style={{ position: 'absolute' }}>
            <div className='text-center'><button onClick={handleChange_profile_btn} style={{ backgroundColor: "skyblue" }} className='rounded p-2 m-1 border-0 bg-indigo-500'>change</button></div>
            <div className={` ${true == "bg-red-100" ? '' : "bg-red-100"}`}></div>
          </div> : ""}
          
        </div>
<div className=''
style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}
>

<div className=' px-3'
style={{width:"100%"}}
>
<div><h1 style={{fontWeight:"bold",textTransform:"capitalize",color:"white",fontSize:"23px"}}>{name}</h1></div>
<div><h3 style={{textTransform:"capitalize",color:"white"}}>{level_state}</h3></div>


</div>

</div>


      
    </div>

        {/* change the banner */}
        {is_editable ? <div className='absolute bottom-0 p-2' style={{ position: 'absolute', right: "0" }}>
          <div><button onClick={handleChange_banner_btn} style={{ backgroundColor: "skyblue" }} className='rounded p-2 m-1 border-0 bg-indigo-500'>change </button></div>
          <div></div>
        </div> : ""}
      </div>
      {/* <img src={img_source} alt="" /> */}
      <input className="" ref={hidden_profile_image_input_ref} style={{ display: "none", backgroundColor: "red", width: "100px", border: "none" }} type="file" onInput={handleChange_profile_image} />
      <input className="" ref={hidden_banner_image_file_input_ref} style={{ display: "none", backgroundColor: "red", width: "100px", border: "none" }} type="file" onChange={handleChange_banner_image} />

    </div>

  )
}

const Hero=({ is_editable }) =>{
  const [screenSize, setscreenSize] = useState(window.innerWidth);
  window.addEventListener('resize',()=>{
    console.log(window.innerWidth);
    console.log({screenSize})
    setscreenSize(window.innerWidth)
  })
  return <>

  {screenSize>572?<HeroDesktop is_editable={is_editable}/>:<HeroMobile is_editable={is_editable}/>}
  
  </>
}

export default Hero