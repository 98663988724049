import React from "react";
import Page_titles from "../../components/Page_titles";

const App = (props) => {
  const link = "https://www.youtube.com/embed/lBVtvOpU80Q";
  const details =
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.";

  const webminar_data = [
    { details: details, link: link, date: Date.now(), title: "abc" },
    { details: details, link: link, date: Date.now(), title: "abc" },
    {
      details: details,
      link: "https://www.youtube.com/embed/1lzK6EYO800",
      date: Date.now(),
      title: "abc",
    },
    { details: details, link: link, date: Date.now(), title: "abc" },
    { details: details, link: link, date: Date.now(), title: "abc" },
    { details: details, link: link, date: Date.now(), title: "abc" },
    { details: details, link: link, date: Date.now(), title: "abc" },
    { details: details, link: link, date: Date.now(), title: "abc" },
    { details: details, link: link, date: Date.now(), title: "abc" },
    { details: details, link: link, date: Date.now(), title: "abc" },
    { details: details, link: link, date: Date.now(), title: "abc" },
    { details: details, link: link, date: Date.now(), title: "abc" },
    { details: details, link: link, date: Date.now(), title: "abc" },
  ];

  const card_maker = (title, date, details, videoLinks) => {
    const code = (
      <div className="third-post">
        
        {/* <div className="blog-img">
         
          <iframe
            className="blk-img1"
           
            src={videoLinks}
            frameborder="0" 
          ></iframe>
        </div> */}
        <div  className="embed-responsive embed-responsive-16by9 m-auto p-3  ">
         <iframe className="embed-responsive-item rounded"  style={{height:"100%",width:"100%"}}  src={videoLinks} allowfullscreen></iframe>
        </div>

        <div className="post-1">
          <div className="post-data">
           
         <a href="javascript:void(0);">
            <span className="h4">Title:</span>
            <span className="h4"> {title}</span>  
            </a>
            <div className="mb-2">
              <span>
                <b className="text-primary">Date:</b> {date}
              </span>
            </div>
            <span>{details}</span>
          </div>
        </div>
      </div>
    );

    return code;
  };

  return (
    <>
      <Page_titles name={props.name} />

      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 col-lg-7">
              <div className="row">
             
                <div className="col-xl-3">
                <a href=" https://www.youtube.com/playlist?list=PLiUcAzb_wzbXe3gihjKsp59wn_mSyMTpE" target="_blank"><button className="btn btn-primary m-3">MARKETING TRAINING PROGRAM BM LIGHT</button></a>
                <a href=" https://www.youtube.com/playlist?list=PLiUcAzb_wzbWQgvM5bI4yflnEnZTKTWqO" target="_blank"><button className="btn btn-primary m-3">TRAINING PROGRAM BM PRIME</button></a>
                <a href=" https://www.youtube.com/playlist?list=PLiUcAzb_wzbVLOgEXorM6h9KgIu5CaBDJ" target="_blank"><button className="btn btn-primary m-3">TRAINING PROGRAM BM PLUS</button></a>
                <a href=" https://www.youtube.com/playlist?list=PLiUcAzb_wzbXqqvVrVdeZzsgxfiPjDfQC" target="_blank"><button className="btn btn-primary m-3">TRAINING PROGRAM BM PRO</button></a>
                <a href=" https://www.youtube.com/playlist?list=PLiUcAzb_wzbVMcI5_AGNeAlesjlPO-v6T" target="_blank"><button className="btn btn-primary m-3">TRAINING PROGRAM BM PLATINUM</button></a>

                    {/* <div className="card-body">
                      {webminar_data.map((d) => {
                        return card_maker(
                          d.title,
                          Date(d.date).toString().slice(0, 24),
                          d.details,
                          d.link
                        );
                      })}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
      
        {/***********************************
      Content body end
  ************************************/}
      </>
    </>
  );
};

export default App;
