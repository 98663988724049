import React, { useEffect, useState } from "react";
import { userSlice } from "../store/userSlice";
import { useSelector } from "react-redux";
import {
  enrolled_referral_users_service,
  user_refeffrals_service,
} from "../services/data_fetch";
import { commission_payments_details } from "../services/user";

const Stats_component = ({ name, data_state }) => {
  
  return (
    <div className="card p-4 bg-primary " style={{borderRadius:"20px"}}>
      <div className="card-body  p-2">
        <div className="depostit-card-media d-flex justify-content-between pb-0">
          <div>
            <h4 className="text-white">{name}</h4>
          </div>
          <div className="d-flex align-items-center ">
            <div
              style={{ minWidth: "50px", height: "50px", borderRadius: "50%" }}
              className="d-flex justify-content-center align-items-center  rounded p-2 bg-white"
            >
              <div>
                <span className="text-success" style={{ fontSize: "22px" }}>
                  {data_state != null ? data_state : 0} <span></span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div id="NewExperience" />
      </div>
    </div>
  );
};

const Level_component = () => {
  const [data_state, setData_state] = useState(0);
  const [level_state, setlevel_state] = useState("Starter");

  useEffect(() => {
    const api_handler = async () => {
      const resp = await commission_payments_details();

      // console.log(resp);
      if (resp.status == 200) {
        let amount = 0;
        const data = resp.data.data;
        // console.log(data);
        data.map((d) => {
          amount += Number(d.commission_amount);
        });
        setData_state(Math.round(amount));
       const recognition_arr=[
        {Stage:"Fresher",target:0},
        {Stage:"Silver",target:25000},
        {Stage:"Gold",target:50000},
        {Stage:"Pearl",target:100000},
        {Stage:"Topaz",target:250000},
        {Stage:"Emerald",target:1000000},
        {Stage:"Ruby",target:2500000},
        {Stage:"Diamond",target:5000000},
        
      
      ]


       if(amount>=recognition_arr[0].target && amount<recognition_arr[1].target ){
        setlevel_state(recognition_arr[0].Stage)
       }
       else if(amount>=recognition_arr[1].target && amount<recognition_arr[2].target ){
        setlevel_state(recognition_arr[1].Stage)
       }
       else if(amount>=recognition_arr[2].target && amount<recognition_arr[3].target ){
        setlevel_state(recognition_arr[2].Stage)
       }
       else if(amount>=recognition_arr[3].target && amount<recognition_arr[4].target ){
        setlevel_state(recognition_arr[3].Stage)
       }
       else if(amount>=recognition_arr[4].target && amount<recognition_arr[5].target ){
        setlevel_state(recognition_arr[4].Stage)
       }
       else if(amount>=recognition_arr[5].target && amount<recognition_arr[6].target ){
        setlevel_state(recognition_arr[5].Stage)
       }
       else if(amount>=recognition_arr[6].target && amount<recognition_arr[7].target ){
        setlevel_state(recognition_arr[6].Stage)
       }
       else if(amount>=recognition_arr[7].target){
        setlevel_state(recognition_arr[7].Stage)
       }
       else{
        setlevel_state("not loaded")
        
       }









      }
    };
    api_handler();
  }, []);
  return (
    <div className="card p-4 bg-primary " style={{borderRadius:"20px"}}>
      <div className="card-body  p-2">
        <div className="depostit-card-media d-flex justify-content-between pb-0">
          <div>
            <h4 className="text-white">Level</h4>
          </div>
          <div className="d-flex align-items-center ">
            <div
              style={{ minWidth: "50px", height: "50px", borderRadius: "50%" }}
              className="d-flex justify-content-center align-items-center  rounded p-2 bg-white"
            >
              <div>
                <span className="text-success" style={{ fontSize: "22px" }}>
                 {level_state}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div id="NewExperience" />
      </div>
    </div>
  );
};
const Balence = () => {
  const [data_state, setData_state] = useState(0);

  useEffect(() => {
    const api_handler = async () => {
      const resp = await commission_payments_details();

      // console.log(resp);
      if (resp.status == 200) {
        let amount = 0;
        const data = resp.data.data;
        // console.log(data);
        data.map((d) => {
          amount += Number(d.commission_amount);
        });
        setData_state(Math.round(amount));
      }
    };
    api_handler();
  }, []);
  return (
    <Stats_component data_state={data_state} name={"Total Earned Rupees"} />
  );
};
const Referral_user_count_component = () => {
  const [user_referrals, setUser_referrals] = useState(null);
  useEffect(() => {
    const api_handler = async () => {
      const resp = await user_refeffrals_service();
      if (resp.status == 200) {
        // console.log(resp);
        setUser_referrals(resp.data.data.length);
      }
      //  console.log(resp)
    };
    api_handler();
  }, []);
  return (
    <Stats_component name={"Referral Users"} data_state={user_referrals} />
  );
};
const Total_enrolled = () => {
  const [data_state, setData_state] = useState(null);
  useEffect(() => {
    const api_handler = async () => {
      const resp = await enrolled_referral_users_service();
      if (resp.status == 200) {
        // console.log(resp, "resp");
        const data = resp.data.data;
        // console.log(data);
        // setData_state(res(p.data.data);
        // console.log(data_state);
        setData_state(data.length);
      }
    };
    api_handler();
  }, []);
  return (
    <Stats_component name={"Enrolled and Upgraded"} data_state={data_state} />
  );
};

function Stats() {
  const user_details = useSelector((a) => a.user.user_details);
  if (user_details == undefined) {
    alert("user data not loaded");
  }

  // referral users

  // for balence
  const [data_state, setData_state] = useState({
    is_loaded: false,
    is_render: false,
    data_arr: [],
    msg: "data not loaded yet",
  });

  return (
    <div class="container-fluid">
      <div className="row">
        {/* main container */}
        {/* <div className="col-xl-3  col-sm-6 ">
          <Balence />
        </div> */}
        {/* user referralls */}
        <div className="col-xl-4  col-sm-12 ">
          <Level_component />
        </div>
        <div className="col-xl-4  col-sm-6 ">
          <Referral_user_count_component />
        </div>
        {/* for user */}
        <div className="col-xl-4  col-sm-6 ">
          <Total_enrolled />
        </div>
        {/* Level */}
        
      </div>
    </div>
  );
}

export default Stats;
