import React, { useEffect, useRef, useState } from "react";
import Page_titles from "../../components/Page_titles";
import { useSelector } from "react-redux";
import { balence_withdraw } from "../../services/payments";
import {
  User_detais_update_on_each_fetch,
  commission_payments_details,
  commission_withdraw_records_user_service,
} from "../../services/user";
import { nanoid } from "@reduxjs/toolkit";
import Msg_display from "../../components/Msg_display";



const Data_render = ({data,n}) => {
   const date_from_api=data.date_of_payment+"";
   const date=date_from_api.slice(0,10)

  return (  
    <tr  key={nanoid()} >
      <td>
        <span>{n+1}</span>
      </td>
      <td>
        <div className="products">
          <div>
            <span>{Math.round(data.commission_amount)} rs</span>
          </div>
        </div>
      </td>
     
      <td>
        {/* for canceled */}
        {/* <span className="badge badge-danger light border-0"> */}
        {/* for paid */}

        {data.amount_sent_status?
        <span className="badge badge-success light border-0">paid</span>
        :
        <span className="badge badge-danger light border-0">not paid</span>

        }
      </td>
      <td>
        <span>{date}</span>
      </td>
      <td>
        <span>{data._id}</span>
      </td>
      <td>
        <span>{data.transfer_details==undefined?"none":data.transfer_details}</span>
      </td>
    </tr>
  
    
  );
}
const App = (props) => {
  const [data_state, setData_state] = useState({
    is_loaded:null,
    is_render:false,
    data_arr:[],
    msg:'data not loaded yet',
  });


  useEffect(() => {
    const api_handler=async()=>{
      const resp=await commission_payments_details();
      let amount=0;
     const data=resp.data.data;
    //  console.log(data)
     data.map((d)=>{
       amount+=Number(d.commission_amount)
     })
     
    //  console.log(resp)
     if(resp.status==200){
      if(Array.isArray(data)==true){
        const reverse_data=data.reverse()
        setData_state({
          is_loaded:true,
          is_render:false,
          data_arr:reverse_data,
          msg:'data not loaded',
        })
      }
     }
     else{
      if(Array.isArray(data)==true){
        const reverse_data=data.reverse()
        setData_state({
          is_loaded:false,
          is_render:false,
          data_arr:[],
          msg:'data not loaded',
        })
      }
     }
    }
    api_handler()
  }, []);
  




 
  

  
  

  return (
    <>
      <Page_titles name={props.name} />

      <div className="container-fluid ">
        <div className="row">
       
       
      <div className="col-xl-12">
        <div className="card ">
          <div className="card-body p-0">
            <div className="table-responsive active-projects style-1">
              <table id="empoloyees-tblwrapper text-center" className="table">
                <thead>
                  <tr>
                    <th key={1}>SL No.</th>
                    <th key={2}>Amount</th>
                    <th key={3}>Status</th>
                    <th key={4}>Date</th>
                    <th key={5}>payments ID</th>
                    <th key={6}>details</th>
                  </tr>
                </thead>
                <tbody>
                  
                {data_state.is_loaded==true?data_state.data_arr.map((data,n)=>{
                 return <Data_render data={data} n={data_state.data_arr.length-n-1} />
                }):<Msg_display  status={data_state.is_loaded} msg={data_state.msg}/>}
                </tbody>
              </table>
            </div>
          </div>
          {/* <div className="text-center"><button  className="btn btn-primary">{data1}</button></div> */}
          <br />

      {/* <div className="text-center"><button  className="btn btn-primary">click</button></div> */}
        </div>
      </div>
      </div>
      </div>
    </>
  );
};

export default App;
