import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  user_details: {},
  enrolled_plan_details:{},
  kyc_status:false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    add_user_details:(state,action)=>{
    const data=action.payload;
    state.user_details=data;
    },
    add_plan_details:(state,action)=>{
      const data=action.payload;
      state.enrolled_plan_details=data;
    },
    update_kyc_status:(state,action)=>{
      state.kyc_status=true;
    }
  },
  // Optionally include extraReducers
});
export const { add_user_details, add_plan_details,update_kyc_status } = userSlice.actions;
