import React, { useState } from "react";

function Modal({ show_modal, setShow_modal,children}) {
  const close_handler = () => {
    setShow_modal(!show_modal);
    // alert("ok")
  };

  return (
    <div
      style={{
        position: "fixed",
        margin: "auto",
        top: "0",
        bottom: "0",
        right: "0",
        left: "0",
        width: "50%",
        minWidth: "300px",
        height: "500px",
        backgroundColor: "white",
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        zIndex:"5"
      }}
    >
      <div
        className="bg-primary d-flex justify-content-center align-items-center "
        style={{
            zIndex:5,
          fontSize: "25px",
          borderRadius: "50%",
          position: "absolute",
          right: "5px",
          width: "30px",
          top: "5px",
          height: "30px",
          textAlign: "end",
          color: "white",
          boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px'
        }}
        onClick={close_handler}
      >
        <span>X</span>
      </div>
      <div className="w-100 h-100">{children}</div>
    </div>
  );
}

export default Modal;
