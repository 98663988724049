import React, { useEffect, useState } from "react";
import PrivatePage from "./panels/PrivatePage"
import PublicPage from "./panels/PublicPage"
import { useDispatch, useSelector } from "react-redux";
import { login_auth_check } from "./services/user";
import { login } from "./store/authSlice";
import { add_plan_details, add_user_details } from "./store/userSlice";

function App() {
  // console.log(process.env.REACT_APP_API)
  // console.log("app starting")
  // alert("ok")
 const dispatch=useDispatch()
 const login_state= useSelector(state=>state.auth.isLogin)
//  console.log("login_state-",login_state)

 useEffect(() => {
  const auth_check=async ()=>{
    const resp=await login_auth_check()
    
    if(resp==false){
     console.log("your are logout")
    }
    else if(resp.data==undefined){
     alert("login plaese")
    }
    else{
     
      const user_details=resp?.data?.data?.user;
      
    
      const enrolled_plan_details=resp?.data?.data?.enrolled_plan
     
  
      if(resp.is_logged==true) {
        dispatch(login(true))
        dispatch(add_user_details(user_details));
        dispatch(add_plan_details({plan_details:enrolled_plan_details}))
      }
    }
   
  }
  auth_check()
  
 }, []);
  return <>

  {login_state? <PrivatePage/>:<PublicPage/>}
  
  </>;
}

export default App;
