import React, { useEffect, useState } from 'react'
import { commission_payments_details } from '../services/user';



const Child=({data,tag,bg})=>{
    return <div className=' my-2 p-3 ' style={{ background:bg, borderRadius:"15px", boxShadow:"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} >


<div className='text-white ' style={{fontSize:"16px"}}>{tag}</div>
<div className='text-white  ' style={{fontSize:"25px",fontWeight:"bold"}}>₹ {data}/-</div>
    </div>
}


// const Balence = () => {
//   const [data_state, setData_state] = useState(0);

//   useEffect(() => {
//     const api_handler = async () => {
//       const resp = await commission_payments_details();

//       // console.log(resp);
//       if (resp.status == 200) {
//         let amount = 0;
//         const data = resp.data.data;
//         // console.log(data);
//         data.map((d) => {
//           amount += Number(d.commission_amount);
//         });
//         setData_state(Math.round(amount));
//       }
//     };
//     api_handler();
//   }, []);
//   return (
//     <Stats_component data_state={data_state} name={"Total Earned Rupees"} />
//   );
// };

function ShowEarning() {

const  [daily,  setDaily] = useState("");
const  [weekly, setWeekly] = useState("");//last 7 days
const  [montly, setMontly] = useState("");//last 30 days
const  [total,  setTotal] = useState("");//total




useEffect(() => {
  const api_handler = async () => {
    const resp = await commission_payments_details();

    // it will geives last 30 days date , we can extract data according to this like weekly daily, monthly
    function last_30_days() {
      const month_with_days = {
          1: 31,//jan
          2: new Date().getFullYear() % 4 == 0 ? 29 : 28,//fab
          3: 31,//march
          4: 30,//april
          5: 31,//may
          6: 30,//june
          7: 31,//july
          8: 31,//aug
          9: 30,//sep
          10: 31,//oct
          11: 30,//nov
          12: 31//dec
      }
  
  
  
      const today = Date();
      const dual_digit = (x) => {
          let str = x + ""
          if (str.length == 1) {
              str = "0" + str
          }
          // if(toString(x)){
          // console.log(str,x,"str");
  
          // }
          return str
  
      }
      const str = new Date().getFullYear() + "-" + dual_digit(new Date().getMonth()) + "-" + dual_digit(new Date().getDate())
      // console.log(str+"")
      // console.log(month_with_date)
      let arr = [];
      const today_date = new Date().getDate();
      const current_month = new Date().getMonth() + 1
      for (let i = today_date; i > 0; i--) {
          arr.push(new Date().getFullYear() + "-" + dual_digit(current_month) + "-" + dual_digit(i))
      }
  
      if (arr.length < 30) {
          const remain_days = 30 - today_date;
          const pre_month_length = month_with_days[new Date().getMonth()]
  
          // console.log({ remain_days, pre_month_length, current_month })
          for (let i = 0; i < remain_days; i++) {
              let str = new Date().getFullYear() + "-" + dual_digit(current_month - 1) + "-" + dual_digit(pre_month_length - i)
              // console.log(str)
              arr.push(str)
          }
      }
  
      // console.log(arr)
      // console.log(today_date)
      return arr
  }
  const last_3o_days_obj={};
  last_30_days().map((e,n)=>{
    last_3o_days_obj[`${e}`]=0;
  })
    // console.log(resp);
    if (resp.status == 200) {
      let total_amount = 0;
      const data = resp.data.data;
      console.log(data);
      data.map((e) => {
        const key=e.createdAt.slice(0,10)
        const item=last_3o_days_obj[`${key}`]
        if(item!=undefined){
            // console.log(item,key);
            last_3o_days_obj[`${key}`]+=e.commission_amount
            // item+=e.commission_amount
        }

        // getting total earned amunt
        total_amount += Number(e.commission_amount);
      });
      // setData_state(Math.round(amount));

const final_last_3o_days_arr_with_amount=Object.entries(last_3o_days_obj);
// console.log(final_last_3o_days_arr_with_amount);
let monthly=0;
let weekly=0;
let daily=0;
final_last_3o_days_arr_with_amount.map((e,n)=>{
    
    if(n==0){
        console.log(e)
        daily+=e[1]  
    }
    if(n<7){
        weekly+=e[1] 
    }
    monthly+=e[1] 
// console.log(n)
})
setDaily(Math.round(daily))
setWeekly(Math.round(weekly))
setMontly(Math.round(monthly))
setTotal(Math.round(total_amount))
    }
  };
  api_handler();
}, []);
  return (
    <div>
         <div class="container-fluid">
      <div className="row">
        {/* main container */}
        <div className="col-xl-6  col-sm-6 ">
        <Child bg={" linear-gradient(to right, #c04848, #480048)"} tag={'All Time Earning'} data={total} />

          {/* <Level_component /> */}
        </div>
        <div className="col-xl-6  col-sm-6 ">
          {/* <Balence /> */}
          <Child tag={"Today's Earning"} bg={" linear-gradient(to right, #4b6cb7, #182848)"} data={daily}/>
        </div>
        {/* user referralls */}
        <div className="col-xl-6  col-sm-6 ">
        <Child bg={" linear-gradient(to right, #1a2980, #26d0ce)"} tag={'Last 7 Days Earnings'} data={weekly} />

          {/* <Referral_user_count_component /> */}
        </div>
        {/* for user */}
        <div className="col-xl-6  col-sm-6 ">
        <Child bg={" linear-gradient(to right, #4776e6, #8e54e9)"} tag={'Last 30 Days Earnings'} data={montly} />

          {/* <Total_enrolled /> */}
        </div>
        {/* Level */}
      
      </div>
    </div>
    </div>
  )
}

export default ShowEarning