import axios from "axios";
import { useContext, useEffect } from "react";
import { add_plan_details, add_user_details } from "../store/userSlice";
import { useDispatch } from "react-redux";

const token = localStorage.getItem("token");

export const login_service = async (email, password) => {
  localStorage.removeItem("token");
  try {
    const resp = await axios.post(process.env.REACT_APP_API + "/user" + "/login", {
      email: email,
      password: password,
    });
    return resp;
  }
  catch (error) {
    console.log(error)
    return error
  }


};
export const signup_service = async (name, password, email, mobile, city, state, zipcode, referral_by_code) => {
  localStorage.removeItem("token");
  try {
    const resp = await axios.post(process.env.REACT_APP_API + "/user" + "/signup", {
      name: name,
      password: password,
      email: email,
      mobile: mobile,
      city: city,
      state: state,
      zipcode: zipcode,
      referral_by_code: referral_by_code,
    });
    return resp;

  } catch (error) {
    return error
  }


};
export const user_details_service = async () => {
  // alert(token)
  try {
    const resp = await axios.post(process.env.REACT_APP_API + "/user" + "/user_details", {},
      { headers: { "authorization": `${token}` } }
    )

    return resp
  } catch (error) {
    console.log(error)
    return error
  }


};
export const login_auth_check = async () => {
  const token = localStorage.getItem("token");

  if (token == "" || token == null || token == undefined) {
    return false;
  } else {
    const user_details_resp = await user_details_service()

    return { is_logged: true, data: user_details_resp.data };
  }
};

// update user details on each fetch, so live user details can be shown
export const update_user_details = async () => {
  const token = localStorage.getItem("token");

  if (token == "" || token == null || token == undefined) {
    alert("you are log out,login please")
    return false;
  } else {
    const user_details_resp = await user_details_service()

    return user_details_resp;
  }
}


export const commission_payments_details = async () => {

  try {
    const resp = await axios.get(process.env.REACT_APP_API + "/user" + "/data/commission_payments",
      { headers: { "Authorization": `${token}` } }
    )

    return resp
  } catch (error) {
    console.log(error)
    return error
  }


};
export const user_purchase_history_service = async () => {
  try {
    const resp = await axios.get(process.env.REACT_APP_API + "/user" + "/user_purchase_history",
      { headers: { "authorization": `${token}` } }
    )
    return resp
  } catch (error) {
    console.log(error)
    return error
  }
}
export const forget_password_otp_request_service = async (email) => {
  localStorage.removeItem("token");
  try {
    const resp = await axios.post(process.env.REACT_APP_API + "/user" + "/forget_password_otp", {
      email: email,
    });
    return resp;

  }
  catch (error) {
    console.log(error)
    return error
  }


};
export const reset_password_service = async (otp, password) => {
  localStorage.removeItem("token");
  try {
    const resp = await axios.post(process.env.REACT_APP_API + "/user" + "/reset_password", {
      otp: otp,
      password: password,
    });
    return resp;

  }
  catch (error) {
    console.log(error)
    return error
  }


};
export const email_otp_verification_service = async (otp) => {

  try {
    const resp = await axios.post(process.env.REACT_APP_API + "/user" + "/email_otp_verification", {
      otp: otp,

    });
    return resp;

  }
  catch (error) {
    console.log(error)
    return error
  }


};


export const banner_upload = async (formData) => {
  try {
    const resp = await axios.post(process.env.REACT_APP_API + "/user/banner_upload", formData, { headers: { "authorization": `${token}` } }
    );
    return resp;
  }
  catch (error) {
    console.log(error)
    return error
  }
}
export const get_image = async (image_name) => {
  try {
    const resp = await axios.get(process.env.REACT_APP_API + "/user/get_image/"+image_name,
      
      { headers: { "authorization": `${token}` },
      responseType: 'blob'
    
    }
    );
    return resp;
  }
  catch (error) {
    console.log(error)
    return error
  }

}
export const profile_image_upload = async (formData) => {
  try {
    const resp = await axios.post(process.env.REACT_APP_API + "/user/profile_image_upload", formData, { headers: { "authorization": `${token}` } }
    );
    return resp;
  }
  catch (error) {
    console.log(error)
    return error
  }
}
