import React, { useState } from 'react'
import LoaderComp from './LoaderComp'

function Msg_display({status,msg}) {
  // const [hide,sethide]=useState(true)
  // setTimeout(()=>{
  //   sethide(false)
  // },2000)
  // console.log(status,msg)
  return (
  <>


      {status==false&&
      
      
        <span className='text text-danger text-center'>{msg}</span>
   
      
      
      }
      {status==null&&<span>
        <div className='w-100 d-flex justify-content-center  align-items-center' style={{position:'absolute',marginLeft:"auto" ,marginRight:"auto",top:"0px",bottom:"0px",textAlign:"center"}}>
        <div className='d-flex justify-content-center btn btn-primary light align-items-center text-center' style={{height:"50px", width:"50px", borderRadius:"50%"}}>
        
        <LoaderComp width={"30px"} height={"30px"} />
        </div>
        </div>
       
        

       
      
      
      
      </span>}
      {status==true&&<span className='text text-success'>{msg}</span>}
  
    </>
  )
}

export default Msg_display