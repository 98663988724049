import React from "react";

function Page_titles(props) {
  return (
    <>
      <div className="page-titles">
        <ol className="breadcrumb">
          <li>
            <h5 className="bc-title">{props.name}</h5>
          </li>
        </ol>
      </div>
   
    </>
  );
}

export default Page_titles;
