import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { login_auth_check } from "../../services/user";

function Affiliate_link_handler() {
  const value = useParams();
  const dispatch = useDispatch();
  const navigate=useNavigate()
  // console.log(value.reffereal_code)
  // console.log("Affiliate_link_handler ###############",)
  localStorage.setItem("reffral_code", value.reffereal_code);

  const login_state = useSelector((state) => state.auth.isLogin);
//   console.log("login_state-", login_state);
if(login_state==true){
    // if user is login , then no user of reffral code
   
    // console.log("login_state-", login_state);
    // console.log(window.location)
    window.location=window.location.origin+"/"
    // navigate("home");
}
else{
    // if user is not login , then create account
    // console.log("login_state-", login_state);
    // console.log(window.location)
    window.location=window.location.origin+"/signup"
}


  return <div style={{position:"fixed",width:"100vw", height:"100vh"}}><h3>redirecrting to login page</h3></div>;
}

export default Affiliate_link_handler;
