import React from "react";

function Chart1() {
  return (
    <div class="container-fluid">
    <div className="row">
      <div className="col-xl-12 col-lg-12 col-sm-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Sales Details</h4>
          </div>
          <div className="card-body">
            <h3>Coming soon..</h3>
            <canvas id="areaChart_2"></canvas>
          </div>
        </div>
      </div>

      {/* <div className="col-xl-12 col-lg-12 col-sm-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">State Wise Sales</h4>
          </div>
          <div className="card-body">
            <canvas id="areaChart_2"></canvas>
          </div>
        </div>
      </div> */}
    </div>
    </div>
  );
}

export default Chart1;
