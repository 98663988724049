import React, { useState } from "react";
import style from "../App.css";

import { Routes, Route, Switch, useParams } from "react-router-dom";

// affiliate releted pages
import Affiliate_Dashboard from "../pages/Affiliate_panel_pages/Dashboard";
import Kyc from "../pages/Affiliate_panel_pages/Kyc";
import Affiliate from "../pages/Affiliate_panel_pages/Affiliate_link";
import My_payments from "../pages/Affiliate_panel_pages/My_payments";
import Leaderboard from "../pages/Affiliate_panel_pages/Leaderboard";

// user pages
import User_dashboard from "../pages/User_pages/User_dashboard"
import Community from "../pages/Affiliate_panel_pages/Community";
import Support from "../pages/Affiliate_panel_pages/Support";
import Training from "../pages/Affiliate_panel_pages/Training";
import Commission_payments   from "../pages/User_pages/commission_payments"
// public pages
// import about from "./pages/public_pages/about";
// import courses from "./pages/public_pages/courses";


import TopNav from "../components/TopNav";
import Sidebar from "../components/Sidebar";
import Web from "../pages/Affiliate_panel_pages/Web";
import Affiliate_link_handler from "../pages/Affiliate_panel_pages/affiliate_link_handler";
import Plans from "../pages/User_pages/plans";
import Course from "../pages/User_pages/course";
import My_account from "../pages/User_pages/my_account";
import Purchase from "../pages/Affiliate_panel_pages/purchase";
import { useSelector } from "react-redux";
import PreLoader from "../components/PreLoader";

// import Affiliate_panel from "./panels/Affiliate_panel";

function Page404(params) {
  const link = useParams();
  // console.log(link);

  return (
    <div className="d-flex column justify-content-center ">
      <br />

      <br />
      <div>
        <h2>{link["*"]}</h2>
        <PreLoader state={null} msg={""} />
      </div>
    </div>
  );
}

function App() {
  const [sideBar_show, setsideBar_show] = useState(false);
  const user_details=useSelector(state=>state.user.user_details);
  const is_user_enrolled=user_details?.is_enrolled;
  // console.log(user_details,"private page")
//  console.log(is_user_enrolled,"is_user_enrolled")


  return (
    <div id="main-wrapper" className={`show ${sideBar_show?"menu-toggle":""} `}>
    <TopNav  setsideBar_show={setsideBar_show} sideBar_show={sideBar_show} />
    {is_user_enrolled==true ?
    <>
      <Sidebar is_show={is_user_enrolled}/>
      <div className="content-body">
        {/* note- if purchased then only show all these pages */}
        <Routes>
        <Route path="" element={<Affiliate_Dashboard name={"Affiliate-Dashboard"} />} />
        <Route path="my-accounts" element={<My_account name={"Account Details"} />} />
        <Route path="enrolled-course" element={<Course name={"Enrolled Course"} />} />
        <Route path="Affiliate-Dashboard" element={<Affiliate_Dashboard name={"Affiliate Dashboard"} />} />
        <Route path="Affiliate-link" element={<Affiliate name={"Affiliate link"} />} />
        <Route path="Community" element={<Community name={"Community"} />} />
        <Route path="KYC" element={<Kyc name={"KYC"} />} />
        <Route path="Leaderboard" element={<Leaderboard name={"Leaderboard"} />}/>
        <Route path="payments" element={<Commission_payments name={" Payments"} />}/>{/* for commission payments details */}
        <Route path="Support" element={<Support name={"Support"} />} />
        <Route path="Training" element={<Training name={"Training"} />} />
        <Route path="/Webinar" element={<Web name={"Webinar"} />} />
        <Route path="/Affiliate/:reffereal_code" element={<Affiliate_link_handler name={"Webminar"} />} />
        <Route path="/Purchase" element={<Purchase name={"Purchase"} />} />
        {/* note- courses mean plans */}
        <Route path="/courses" element={<Plans name={"Courses"} />} />
        
        <Route path="*" element={<Page404 />} />
        {/* <Route path="*" element={<Page404 />} /> */}
      </Routes>
       
        
      </div>
      </>
    :
    
    // if not enrolled then show these
    <>
      <Sidebar is_user_enrolled={is_user_enrolled}/>
      <div className="content-body">
        {/* note- if purchased then only show all these pages */}
        <Routes>
        <Route path="" element={<Plans name={"Courses"} />} />
        <Route path="my-accounts" element={<My_account name={"Account Details"} />} />
        <Route path="Community" element={<Community name={"Community"} />} />
        <Route path="Support" element={<Support name={"Support"} />} />
        <Route path="Training" element={<Training name={"Training"} />} />
        <Route path="/Purchase" element={<Purchase name={"Purchase"} />} />

        {/* note- courses mean plans */}
        <Route path="/courses" element={<Plans name={"Courses"} />} />
        
        <Route path="*" element={<Page404 />} />
        {/* <Route path="*" element={<Page404 />} /> */}
      </Routes>
       
        
      </div>
      </>
  }
      
    </div>
  );
}

export default App;
