import React, { useEffect, useState } from "react";
import Page_titles from "../../components/Page_titles";
import Kyc_forms from "../../components/Kyc_forms";
import { kyc_upload_service } from "../../services/kyc";
import { useSelector } from "react-redux";

const App = (props) => {
  const [data_status, setdata_status] = useState(false);
  const [data, setdata] = useState([]);
  const [loader,setLoader]=useState({status:"",msg:""});
  const user_details = useSelector((state) => state.user.user_details);

  // if undefined then nothing will run
  // console.log(user_details, "user_details");
  
  const kyc_upload = user_details?.kyc_upload;
  const kyc_status = user_details?.kyc_status;
  const kyc_status_msg = user_details.kyc_status_msg;
  const kyc_data = user_details.kyc;
  
  useEffect(() => {
    if(user_details!=undefined){
      setdata_status(true)
    }
    if (kyc_upload == true) {
      setdata([
        { key: "Name", value: kyc_data.name },
        { key: "Adhar Card No.", value: kyc_data.adhar_card },
        { key: "PAN Card", value: kyc_data.pan_card },
        { key: "Bank Name", value: kyc_data.bank_account_details.bank_name },
        {
          key: "Bank Account No",
          value: kyc_data.bank_account_details.account_number,
        },
        { key: "IFSC code", value: kyc_data.bank_account_details.ifsc_code },
      ]);
    }
    // console.log(data,kyc_data)
  }, []);

  const data_render = (data_arr) => {
    // console.log(data_arr);
    return data_arr.map((d, n) => {
      const code = (
        <tr key={(n = "key")}>
          <td>
            <div style={{ textAlign: "left" }}>{d.key}</div>
          </td>
          <td>
            <div style={{ textAlign: "left" }}>{d.value}</div>
          </td>
        </tr>
      );
      return code;
    });
  };

  return (
    <>
      <Page_titles name={props.name} />
      <div className="container-fluid">
        <div className="row">
          {data_status==false ? (
            "no data"
          ) : (
            <>
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body p-4 ">
                  <h4>
                    <span>KYC status:</span>{" "}
                    {kyc_status == true ? (
                      <button className="btn btn-success">Verified</button>
                    ) : (
                      <button className="btn btn-danger">Not Verified</button>
                    )}{" "}
                  </h4>
                  <h4>
                    <span className="text-primary">{kyc_status_msg}</span>
                  </h4>
                </div>
              </div>
            </div>
             <div className="col-xl-12">
             <div className="card">
               <div className="card-body p-0">
                 <div className="table-responsive active-projects style-1">
                   <div className="tbl-caption"></div>
                   <table className="table">
                     <thead></thead>
                     <tbody>
                     {kyc_status==true ? data_render(data) :""}
                       {kyc_upload==true  ? "" : <Kyc_forms />}
                     </tbody>
                   </table>
                 </div>
               </div>
             </div>
           </div>
           </>
          )}
        </div>
      </div>
    </>
  );
};

export default App;
