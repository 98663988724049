import React from "react";
import { Link } from "react-router-dom";

function TopNav({ setsideBar_show, sideBar_show }) {
  const sideBar_hide_show_handler = () => {
    setsideBar_show(!sideBar_show);
  };
  // "#265AC8"

    return (
      <>
        <div
          style={{height: "50px",background: "#265AC8",position: "fixed",zIndex: "20",width: "100vw",
          }}
          className="d-flex justify-content-between"
        >
         <div className="d-flex justify-content-center align-items-center m-2" >
       
         <div>
    <img src="../assets/logo2.png" width={"130px"} height={"35px"}  alt="" />
  
         </div>
  
         </div>
          <div className="d-flex justify-content-end align-items-center" style={{paddingRight:"10px"}}>
        
               <div className="nav-link text-white ">
                <a href={"https://badamission.com/"}>
                  <div
                    className="nav-link text-white" style={{marginRight:"10px"}} >
                      <h5 className="text-white">Home</h5>
                    
                  </div>
                  </a>
                  </div>
              
            
              <Link to={"/login"}>
                    <div className="nav-link text-white " style={{marginRight:"10px"}}><h5 className="text-white">Login</h5></div>
                  </Link>
              <Link to={"/signup"}>
                    <div className="nav-link text-white " style={{marginRight:"10px"}}><h5 className="text-white">Signup</h5></div>
                  </Link>
                  <Link to={"/forgot-password"}>
                    <div className="nav-link text-white" style={{marginRight:"5px"}}><h5 className="text-white"> Forget Password</h5></div>
                  </Link>
               
          </div>
          
        </div>
      </>
    );

}

export default TopNav;
