import axios from "axios";
const token = localStorage.getItem("token");

const url=process.env.REACT_APP_API
export const kyc_upload_service = async ({formData}) => {



  try {
    const resp = await axios.post(url+"/user"+"/kyc_upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${token}`,
        },
      }
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const add_kyc_service = async (data) => {

  try {
    const resp = await axios.post(url+"/user"+"/add_kyc",
       {
        payload:{
          name:data.name,
          adhar_card_number:data.adhar_card_number,
          pan_card_number:data.pan_card_number,
          adress:data.adress,
          bank_name:data.bank_name,
          bank_account_no:data.bank_account_no,
          bank_ifsc_code:data.bank_ifsc_code,
          upload_type_of_document:data.upload_type_of_document,
        }
       

       },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    return resp;
  } catch (error) {
    console.log(error);
    return error;
  }
};