
import axios from "axios";


const token = localStorage.getItem("token");
export const all_plans_service=async()=>{
   

  try {
    const resp = await axios.get(process.env.REACT_APP_API + "/general"+"/plans")
    return resp
  } catch (error) {
    return error
  }
}

export const user_refeffrals_service=async()=>{
 
try {
  const resp = await axios.get(process.env.REACT_APP_API + "/user"+"/data/user_referrals",
   {headers: {"authorization" : `${token}`} }
  )
  return resp
} catch (error) {
 console.log(error)
  return error
}
}
export const enrolled_referral_users_service=async()=>{
 
  try {
    const resp = await axios.get(process.env.REACT_APP_API + "/user"+"/data/enrolled_referral_users",
     {headers: {"authorization" : `${token}`} }
    )
    return resp
  } catch (error) {
   console.log(error)
    return error
  }
  }