import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { login } from "../../store/authSlice";
import { forget_password_otp_request_service, login_service, user_details_service } from "../../services/user";
import { add_plan_details, add_user_details } from "../../store/userSlice";
import Msg_display from "../../components/Msg_display";

// import Page_titles from "../../components/Page_titles";

const Forget_password = (props) => {
  const navigate=useNavigate()
  const [msg_state,setMsg_state]=useState({status:"",msg:""})
    const enterd_email_id=useRef();
 
  const handler = async (e) => {
    e.preventDefault();
    setMsg_state({status:null,msg:""})
    const email_value=enterd_email_id.current.value

   
    const resp=await forget_password_otp_request_service(email_value);
    
    // console.log(resp,"data")
    if(resp.status==200){
      const msg=resp?.data?.msg
      setMsg_state({status:true,msg:msg})
      setTimeout(()=>{
        navigate("/reset-password")
      },2000)
    }
 
    else{
    const msg=resp.response?.data?.msg
      setMsg_state({status:false,msg:msg})
  
    }
  };
  
  return (
    <>
    {/* <div className="content-body"></div> */}
      {/* <h3> {props.name} </h3> */}
      <div className="d-flex justify-content-center align-items-start "   style={{backgroundColor:"white",height:"100vh",width:"100vw",padding:"50px"}}>
      <div style={{width:"70vh", minWidth:"450px"}} className="m-5">
        <div className="card ">
          <div className="card-body">
            <h3 className="text-center"> Generate OTP</h3>
            <br />
            <div className="basic-form">
              <form>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Email</label>
                  <div className="col-sm-9">
                    <input
                    ref={enterd_email_id}
                      type="email"
                      className="form-control"
                      placeholder="Enter your registerd Email"
                    />
                  </div>
                </div>
               
                
       
                <div className="row">
                  <div className="mb-3 col-md-2 w-100 text-center">
                    <div>
                     <Msg_display status={msg_state.status} msg={msg_state.msg}/>
                    
                    </div>
                    <br />
                    <div>
                    <button
                      type="submit"
                      onClick={handler}
                      className="btn btn-primary w-50 "
                    >
                      Submit
                    </button>
                    </div>
            
                    <br />
                  
                  </div>
                </div>
                <div className="row">
                
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      </div>
      
    </>
  );
};

export default Forget_password;
