import React from "react";
import { Link } from "react-router-dom";

function TopNav({ setsideBar_show, sideBar_show }) {
  const sideBar_hide_show_handler = () => {
    setsideBar_show(!sideBar_show);
  };
  // "#265AC8"

    return (
      <>
        <div
          style={{height: "50px",background: "#265AC8",position: "fixed",zIndex: "20",width: "100%",
          }}
          className="d-flex justify-content-between"
        >
         <div className="d-flex justify-content-center align-items-center" >
         <div className="d-flex justify-content-center align-items-center   " onClick={sideBar_hide_show_handler} style={{backgroundColor:"blue", width: "50px", height: "50px", marginRight:"10px" }}
          >
          
            <div style={{}}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="30px"
                height="27px"
                fill="white"
                viewBox="0 0 50 50"
              >
                <path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z"></path>
              </svg>
            </div>
          </div>
         <div>
    <img src="../assets/logo2.png" width={"130px"} height={"35px"}  alt="" />
  
         </div>
  
         </div>
          <div className="d-flex justify-content-end align-items-center" style={{paddingRight:"10px"}}>
       
                  <Link to={"/my-accounts"}>
                    <div className="nav-link text-white " style={{marginRight:"10px"}}><h5 className="text-white">My Accounts</h5></div>
                  </Link>
     
                  <Link to={"/courses"}>
                    <div className="nav-link text-white" style={{marginRight:"5px"}}><h5 className="text-white">Courses</h5></div>
                  </Link>
               
          </div>
          
        </div>
      </>
    );

}

export default TopNav;
