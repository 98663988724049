import React, { useEffect, useState } from "react";
import Page_titles from "../../components/Page_titles";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

function Course(props) {
  const [data_state,setDate_state]=useState({is_loaded:false,data_obj:{}, msg:"refresh if not loaded"})
  
  const navigation= useNavigate()
  const user_details = useSelector((state) => state.user);
  const enrolled_plan_details=user_details?.enrolled_plan_details?.plan_details

// console.log(user_details)
  //   let plan_details=user_details.
  const btn_handler=()=>{
    navigation('/courses')
  }
    
useEffect(()=>{
if(enrolled_plan_details!=null){
  const plan_enrollment_status = user_details?.user_details?.is_enrolled;
  const meeting_link=enrolled_plan_details?.meeting_link
  const meeting_msg=enrolled_plan_details?.meeting_msg
  setDate_state({
    is_loaded:true,
data_obj:{
  plan_enrollment_status,meeting_link,meeting_msg
}
  })

}
},[])
  return (
    <>
      <Page_titles name={props.name} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
        {data_state.is_loaded==true&&     <div className="card">
              <div className="card-body p-3">
                {data_state?.data_obj?.plan_enrollment_status!=true?<div>
                 <h5>You have not  enrolled any course </h5>
                 <br />
                 <button onClick={ btn_handler } className="btn btn-primary">Go to Couse Purchase Page</button>
                </div>:<div>
                    <div> <h5>
                        taken Plan:<span className="text-black">{enrolled_plan_details?.plan_name}</span> 
                        </h5>
                        </div>
                    <br />
                   
                    <br />
                    <div className="card p-3" >
                    {data_state?.data_obj?.meeting_link!=undefined?
               <a href={data_state?.data_obj?.meeting_link} target="_blank"> <button className="btn btn-primary">join Classroom</button></a>
                    :""
                    }
                    <br />
                    
                     {data_state?.data_obj?.meeting_msg!=undefined?
                       <span className="text-black">Message: {data_state?.data_obj?.meeting_msg}</span>
                    :""
                    }
                    </div>
                    </div>}
              </div>
            </div>}
          </div>
        </div>
      </div>
    </>
  );
}

export default Course;
