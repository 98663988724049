import React, { useEffect, useState } from "react";
import Page_titles from "../../components/Page_titles";
import Style_obj from "../../style_obj";
import { all_plans_service } from "../../services/data_fetch";
import { user_details_service } from "../../services/user";

const App = (props) => {
  const curret_url_origin = window.location.origin;
  // console.log("ok");

  // geting all plans
  const [plans, setplans] = useState([]);
  const [user_reffral_code, setuser_reffral_code] = useState("");

  useEffect(() => {
    const api_handler = async () => {
      const resp = await all_plans_service()
      const user_data = await user_details_service();

      // console.log(user_data);
      // console.log(data);
      if(resp.status==200){
        setuser_reffral_code(user_data?.data?.data?.user_referral_code)
       
        if(Array.isArray(resp.data.data)==true){
          setplans(resp.data.data);
        }
      }
  
    };
    api_handler();
  }, []);

 

  // initial copy link
  const [refral_code, setrefral_code] = useState(
    `select plan`
  );

  const select_handler = (d) => {
    const selected_data=d.target.value
    // console.log(selected_data)
    if(selected_data==""){
      setrefral_code(`select plan`);
    }
    else{
      setrefral_code(`${curret_url_origin}/Affiliate/${d.target.value}`);
    }
    
  };

  // copy link to clipboard
  const [copy_btn_text1, setcopy_btn_text1] = useState("copy");
  const [copy_btn_text2, setcopy_btn_text2] = useState("copy");
  const copy_handler = (param,btn) => {
    // console.log(param);
    navigator.clipboard.writeText(param);
    setcopy_btn_text1("copied")
    if(btn==1){
      setcopy_btn_text1("copied")
    }
    else if(btn==2){
      setcopy_btn_text2("copied")
    }
   alert(`copied link- ${param}  `)
  };

  return (
    <div className="container-fluid">
      <Page_titles name={props.name} />
      <br />
      <div className="row">
        <div className="col-xl-12 col-lg-6">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Referal Code</h4>
            </div>
            <div className="card-body">
              <div
                style={{
                  width: "100%",
                  backgroundColor: Style_obj().background_color_field,
                }}
                className="d-flex justify-content-between p-2"
              >
                <div className="p-2 text-black" >{user_reffral_code!=""?user_reffral_code.toUpperCase():""}</div>

                {/* <div
                  onClick={() => {
                    copy_handler(user_reffral_code,1);
                  }}
                  style={{
                    backgroundColor: Style_obj().background_color_button,
                    textAlign: "center",
                  }}
                  className="p-1 rounded"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.7161 16.2234H8.49609"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M15.7161 12.0369H8.49609"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.2511 7.86011H8.49609"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.9085 2.74982C15.9085 2.74982 8.23149 2.75382 8.21949 2.75382C5.45949 2.77082 3.75049 4.58682 3.75049 7.35682V16.5528C3.75049 19.3368 5.47249 21.1598 8.25649 21.1598C8.25649 21.1598 15.9325 21.1568 15.9455 21.1568C18.7055 21.1398 20.4155 19.3228 20.4155 16.5528V7.35682C20.4155 4.57282 18.6925 2.74982 15.9085 2.74982Z"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span className="text-white"> {copy_btn_text1}</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-6 col-lg-6">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Generate Course Referral Link</h4>
            </div>

            <div className="card-body">
              <div className="basic-form">
                <form
                  action="#"
                  style={{
                    backgroundColor: Style_obj().background_color_field,
                  }}
                >
                  <div className="input-group mb-3">
                    <label className="input-group-text mb-0">Options</label>
                    <select
                      onChange={(a) => {
                        select_handler(a);
                      }}
                      style={{
                        backgroundColor: Style_obj().background_color_field,
                      }}
                      className="default-select  form-control wide"
                    >
                      <option selected="" value="">Choose...</option>
                      {plans.map((d) => {
                        return (
                          <option value={user_reffral_code}>{d.plan_name}</option>
                        );
                      })}
                      {/* <option value={1}>One</option>
                      <option value={2}>Two</option>
                      <option value={3}>Three</option> */}
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Referral Link</h4>
            </div>
            {/* card body */}
            <div className="card-body">
              <div
                style={{
                  width: "100%",
                  backgroundColor: Style_obj().background_color_field,
                }}
                className="d-flex justify-content-between p-2"
              >
                <div className="p-2">{refral_code}</div>

                {/* <div
                  onClick={() => copy_handler(refral_code,2)}
                  style={{
                    backgroundColor: Style_obj().background_color_button,
                    textAlign: "center",
                  }}
                  className="p-1 rounded"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.7161 16.2234H8.49609"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M15.7161 12.0369H8.49609"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.2511 7.86011H8.49609"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.9085 2.74982C15.9085 2.74982 8.23149 2.75382 8.21949 2.75382C5.45949 2.77082 3.75049 4.58682 3.75049 7.35682V16.5528C3.75049 19.3368 5.47249 21.1598 8.25649 21.1598C8.25649 21.1598 15.9325 21.1568 15.9455 21.1568C18.7055 21.1398 20.4155 19.3228 20.4155 16.5528V7.35682C20.4155 4.57282 18.6925 2.74982 15.9085 2.74982Z"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span className="text-white"> {copy_btn_text2}</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;

{
  /* <div className="col-xl-12 col-lg-12">
        <div className="col-xl-6 col-lg-12">
         <div className="card">
            <div className="card-header">
              <h4 className="card-title">Custom select</h4>
            </div>

            <div className="card-body">
              <div className="basic-form">
                <form action="#">
               
                  <div className="input-group mb-3">
                    <select className="default-select form-control wide bleft">
                      <option selected="">Choose...</option>
                      <option value={1}>One</option>
                      <option value={2}>Two</option>
                      <option value={3}>Three</option>
                    </select>
                    <label className="input-group-text  mb-0">Options</label>
                  </div>
                 
                 
                </form>
              </div>
            </div>

            <div className="card-body">
              <div  className="card-body" >
                dsfcds
              </div>
            </div>
          </div>
          </div>
        
        </div> */
}
