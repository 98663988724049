import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { login } from "../../store/authSlice";
import { login_auth_check, login_service, user_details_service } from "../../services/user";
import { add_plan_details, add_user_details } from "../../store/userSlice";
import Msg from "../../components/Msg";
import Msg_display from "../../components/Msg_display";

// import Page_titles from "../../components/Page_titles";

const Login = (props) => {
  localStorage.removeItem("token")
  const [data_state,setData_state]=useState({is_loaded:"",data_obj:{},msg:"",is_render:true})

    const dispatch=useDispatch();
    const navigate = useNavigate();
    const enterd_email_id=useRef();
    const enterd_passwor=useRef();
  const login_handler = async (e) => {
    e.preventDefault();
    const email_value=enterd_email_id.current.value
    const passoword_value=enterd_passwor.current.value

    setData_state({is_loaded:null,data_obj:{},msg:"",is_render:true});

    if(email_value=="" ||passoword_value=="" ){
      
      setData_state({is_loaded:false,data_obj:{},msg:"Fill all the Fields",is_render:true});
      return;
    }


   
    const resp=await login_service(email_value,passoword_value);

    // console.log(resp,"data")


   
    if(resp.status==200){
      localStorage.setItem("token",resp.data.token)
     
      setData_state({is_loaded:true,data_obj:{},msg:"login Successful, please verify your email",is_render:true});
     

      dispatch(login(true))
      const user_details_resp=await user_details_service();
      // console.log(user_details_resp)
      const user_details=user_details_resp?.data?.data?.user;
      dispatch(add_user_details({user_details:user_details}));
      if(user_details?.is_enrolled==true){
        const enrolled_plan_details=user_details?.enrolled_plan ;
        dispatch(add_plan_details({plan_details:enrolled_plan_details}))
        window.location.pathname="/Affiliate-Dashboard"
      }
      else{
 // console.log(user_details)
 window.location.pathname="/courses"
 // navigate("/courses");
      }
     
     
      
     
    }

    else{
      const data=resp.response?.data?.msg
      setData_state({is_loaded:false,data_obj:{},msg:data,is_render:true});
    }
  };
 
  return (
    <>
    {/* <div className="content-body"></div> */}
      <div className="d-flex justify-content-center align-items-start "   style={{backgroundColor:"white",height:"100vh",width:"100vw",padding:"50px"}}>
      <div style={{width:"70vh"}} className="m-5">
        <div className="card">
          <div className="card-body">
            {/* {console.log("login")} */}
            <h3 className="text-center">Login</h3>
            <br />
            <div className="basic-form">
              <form>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Email</label>
                  <div className="col-sm-9">
                    <input
                    ref={enterd_email_id}
                      type="email"
                      className="form-control"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Password</label>
                  <div className="col-sm-9">
                    <input
                    ref={enterd_passwor}
                      type="password"
                      className="form-control"
                      placeholder="Password"
                    />
                  </div>
                </div>
                <br />
          
                <div className="row">
                <div className="mb-3 col-md-2 w-100 text-center">
                <div className="mb-3  w-100 text-center">
                 {/* <Msg is_loaded={data_state.is_loaded}  is_render={true} msg={data_state.msg}/> */}
                 <Msg_display status={data_state.is_loaded} msg={data_state.msg}/>
                  </div>
                  </div>
                  <div className="mb-3 col-md-2 w-100 text-center">
                    <button
                      type="submit"
                      onClick={login_handler}
                      className="btn btn-primary w-50 "
                    >
                      Login
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-md-2 w-100">
                    <Link to="/signup">
                      <h5 className="text-center">
                        not an Account ?{" "}
                        <span className="text-blue">Create Account</span>
                      </h5>
                    </Link>
                    <h4 className="text-center">or</h4>
                    <Link to="/forgot-password">
                      <h5 className="text-center">
                        forget Passowrd ?{" "}
                        <span className="text-blue">Reset your password</span>
                      </h5>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      </div>
      
    </>
  );
};

export default Login;
