import axios from "axios";

const token = localStorage.getItem("token");
export const plan_details_and_payment_calc=async (args)=>{ 
    try {
      const resp = await axios.post(process.env.REACT_APP_API +"/payments" +"/plan_and_payments_calc",{
          
      id:args.plan_id,
      is_coupon:args.is_coupon,
      coupon_code:args.coupon_code,
          
      },
      { headers: {"authorization" : `${token}`} }
      )
       
      
      return resp
      } catch (error) {
       return error
      }
    
     
}
export const payment_order = async (seleted_plan_id,is_coupon,coupon_code) => {
  // alert(token)
    try {
    const resp = await axios.post(process.env.REACT_APP_API +"/payments" +"/order",{
        seleted_plan_id:seleted_plan_id,
        is_coupon:is_coupon,
        coupon_code:coupon_code,
    },
    { headers: {"authorization" : `${token}`} }
    )
     
   
    return resp
    } catch (error) {
     return error
    }
  
    
  };
export const payment_validation = async (data) => {
   
    try {
    const resp = await axios.post(process.env.REACT_APP_API +"/payments" +"/validation",{
        payload:data.res,
        seleted_plan_id:data.seleted_plan_id,
        transaction_id:data.transaction_id,
        is_coupon:data.is_coupon,
        coupon_code:data.coupon_code
  
    },
    { headers: {"Authorization" : `${token}`} }
    )
     
    return resp
    } catch (error) {
     return error
    }
  
  };


  