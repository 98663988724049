
import React from "react";
import Page_titles from "../../components/Page_titles";



const App = (props) => {
  return <div>
<Page_titles  name={props.name}/>

    
  </div>
}

export default App;