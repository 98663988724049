import React from "react";
import Page_titles from "../../components/Page_titles";
import data from "get-random-data";

const App = (props) => {
  const mydata = data.random_string(4, 5);
  const arr = [
    {
      name: "ptcet",
      amount: 91348,
    },
    {
      name: "ttjgp",
      amount: 76769,
    },
    {
      name: "gsgvt",
      amount: 35407,
    },
    {
      name: "cxhnl",
      amount: 39898,
    },
    {
      name: "umoyg",
      amount: 63495,
    },
    {
      name: "frnrk",
      amount: 63396,
    },
    {
      name: "tbdwt",
      amount: 22600,
    },
    {
      name: "bukck",
      amount: 21953,
    },
    {
      name: "mjfob",
      amount: 76386,
    },
    {
      name: "wmqqt",
      amount: 57271,
    },
  ];
  for (let i = 0; i < 10; i++) {
    const obj = {
      name: data.random_string(5, 1)[0],
      amount: data.random_number(5, 1)[0],
    };
    arr.push(obj);
  }
  // console.log(arr);

  // console.log(mydata)

  const render_data = (array_data) => {
    return (
      <>
        {array_data.map((data, n) => {
          return n % 2 != 0 ? (
            <div
              style={{ backgroundColor: "#fff", height: "30px" }}
              className="d-flex justify-content-evenly align-center align-items-center"
            >
              <span>{data.name} </span>
              <span>{data.amount} </span>
            </div>
          ) : (
            <div
              style={{ backgroundColor: "#96bcfa", height: "30px" }}
              className="d-flex justify-content-evenly align-center align-items-center"
            >
              <span>{data.name} </span>
              <span>{data.amount} </span>
            </div>
          );
        })}
      </>
    );
  };
  return (
    // <>
    //   <Page_titles name={props.name} />

    //   <div className="container-fluid">
    //     <div className="row">
          
    //       <div className="col-xl-3  col-sm-6">
    //         <div className="card overflow-hidden">
    //           <div
    //             style={{
    //               backgroundColor: "#dce8fa",
    //               textTransform: "capitalize",
    //               padding: "20px",
    //             }}
    //             className="row"
    //           >
    //             <div
    //               style={{ height: "50px", backgroundColor: "" }}
    //               className="d-flex justify-content-center flex-column  align-items-center"
    //             >
    //               <h4>Weeks</h4>
    //             </div>
    //             {render_data(arr)}
    //           </div>
    //         </div>
    //       </div>

    //       <div className="col-xl-3  col-sm-6">
    //         <div className="card overflow-hidden">
    //           <div
    //             style={{
    //               backgroundColor: "#dce8fa",
    //               textTransform: "capitalize",
    //               padding: "20px",
    //             }}
    //             className="row"
    //           >
    //             <div
    //               style={{ height: "50px", backgroundColor: "" }}
    //               className="d-flex justify-content-center flex-column  align-items-center"
    //             >
    //               <h4>Weeks</h4>
    //             </div>
    //             {render_data(arr)}
    //           </div>
    //         </div>
    //       </div>

    //       <div className="col-xl-3  col-sm-6">
    //         <div className="card overflow-hidden">
    //           <div
    //             style={{
    //               backgroundColor: "#dce8fa",
    //               textTransform: "capitalize",
    //               padding: "20px",
    //             }}
    //             className="row"
    //           >
    //             <div
    //               style={{ height: "50px", backgroundColor: "" }}
    //               className="d-flex justify-content-center flex-column  align-items-center"
    //             >
    //               <h4>Weeks</h4>
    //             </div>
    //             {render_data(arr)}
    //           </div>
    //         </div>
    //       </div>

    //       <div className="col-xl-3  col-sm-6">
    //         <div className="card overflow-hidden">
    //           <div
    //             style={{
    //               backgroundColor: "#dce8fa",
    //               textTransform: "capitalize",
    //               padding: "20px",
    //             }}
    //             className="row"
    //           >
    //             <div
    //               style={{ height: "50px", backgroundColor: "" }}
    //               className="d-flex justify-content-center flex-column  align-items-center"
    //             >
    //               <h4>Weeks</h4>
    //             </div>
    //             {render_data(arr)}
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </>
    <>
        <Page_titles name={props.name} />

   <div className="container-fluid">
    <div className="row">
      <div className="col-xl-3  col-sm-6">
    <h3>Comming soon . . .</h3>
    </div>
    </div>
    </div>
    </>
  );
};

export default App;
