import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { login } from "../../store/authSlice";
import { login_service, reset_password_service, user_details_service } from "../../services/user";
import { add_plan_details, add_user_details } from "../../store/userSlice";
import Msg_display from "../../components/Msg_display";


const ResetPassword = (props) => {
    const [msg_state,setMsg_state]=useState({status:"",msg:""})
    const navigate = useNavigate();
    const otp=useRef();
    const enterd_passwor=useRef();
  const handler = async (e) => {
    e.preventDefault();
    setMsg_state({status:null,msg:"msg"})
    const otp_value=otp.current.value
    const passoword_value=enterd_passwor.current.value
   
    const resp=await reset_password_service(otp_value,passoword_value);

    // console.log(resp,"data")
    if(resp.status==200){
        const msg=resp?.data?.msg
        setMsg_state({status:true,msg:msg})
     
     setTimeout(()=>{
        navigate("/login");
     },2000)
     
    }

    else{
        const msg=resp.response?.data?.msg
        setMsg_state({status:false,msg:msg})
    }
  };
  
  return (
    <>
  
      {/* <h3> {props.name} </h3> */}
      <div className="d-flex justify-content-center align-items-start "   style={{backgroundColor:"white",height:"100vh",width:"100vw",padding:"50px"}}>
      <div style={{width:"70vh",minWidth:"450px"}} className="m-5">
        <div className="card ">
          <div className="card-body">
            <h3 className="text-center">Reset Password</h3>
            <br />
            <div className="basic-form">
              <form>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">OTP</label>
                  <div className="col-sm-9">
                    <input
                    ref={otp}
                      type="text"
                      className="form-control"
                      placeholder="Enter OTP"
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Password</label>
                  <div className="col-sm-9">
                    <input
                    ref={enterd_passwor}
                      type="password"
                      className="form-control"
                      placeholder="Password"
                    />
                  </div>
                </div>
            
                <div className="row">
                  <div className="mb-3 col-md-2 w-100 text-center">
                  <div>
                      {msg_state.status==true?
                      <>
                      <span className="text-success">{msg_state.msg}</span>
                       <br />
                       <span className="">{"redirecting to login page"}</span>
                      </>
                      :
                      <Msg_display status={msg_state.status} msg={msg_state.msg}/>
                      }
                     
                    
                    
                    </div>
                    <br />
                    <div>

                    <button
                      type="submit"
                      onClick={handler}
                      className="btn btn-primary w-50 "
                    >
                      Submit
                    </button>

                    </div>
                   
                  </div>
                </div>
                <div className="row">
              
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      </div>
      
    </>
  );
};

export default ResetPassword;
