
import React from "react";
import Page_titles from "../../components/Page_titles";
import Stats from "../../components/Stats";
import Chart1 from "../../components/Chart1";
import Hero from "../../components/Hero";
import ShowEarning from "../../components/ShowEarning";



const App = (props) => {
  return < >
    <Page_titles  name={props.name}/>
  <div className="container-fluid">
    <Hero is_editable={false}/>
    
    <ShowEarning/>
  <Stats/>


    

<Chart1/> 

  </div>

  </>
}

export default App;

{/* <div className="col-xl-3  col-sm-6">
            <div className="card overflow-hidden">
              <div
                style={{
                  backgroundColor: "#dce8fa",
                  textTransform: "capitalize",
                  padding: "20px",
                }}
                className="row"
              >
                <div
                  style={{ height: "50px", backgroundColor: "" }}
                  className="d-flex justify-content-center flex-column  align-items-center"
                >
                  <h4>Weeks</h4>
                </div>
                {render_data(arr)}
              </div>
            </div>
          </div> */}