import React, { useRef } from "react";
import { useEffect, useState } from "react";
import Page_titles from "../../components/Page_titles";
import { all_plans_service } from "../../services/data_fetch";
import {
  payment_order,
  payment_validation,
  plan_and_payment_calc,
  plan_details_and_payment_calc,
} from "../../services/payments";
import { Link, useNavigate } from "react-router-dom";
import LoaderComp from "../../components/LoaderComp";
import Modal from "../../components/Modal";
import Msg_display from "../../components/Msg_display";

const Purchase = ({ props_selected_plan_id,setPlans_data ,plans_data}) => {
  // console.log("Purchase ***********");
  const navigate=useNavigate()
  const [show_modal,setShow_modal]=useState(false)
  const [payment_status_msg,setPayment_status_msg]=useState({status:null,msg:"waiting for validation ..."})

  const [loader, setLoader] = useState({msg:"",status:false});
  const [btn_pay_loader,setBtn_pay_loader]=useState(false);
  const [coupon_code_state, setCoupon_code_state] = useState({is_coupon:false,coupon_code:null});
  const [coupon_code_msg, setCoupon_code_msg] = useState({
    status: false,
    msg: "",
  });
//   const [plans_data, setPlans_data] = useState(null);
 
  const coupon_code_ref = useRef();

//   it is used to update data if coupon code is applied
  const coupon_apply_button_handler = async (e) => {
    e.preventDefault();
    //getting enterd coupon code
    setCoupon_code_msg({msg:"",status:null})
    const coupon_code_value = coupon_code_ref.current.value;
    // console.log(coupon_code_value, "coupon_code_value");
    
    try {
      if (coupon_code_value == "") {
            setCoupon_code_msg({ status: false, msg: "enter coupon code" });
            // alert("enter coupon code");
            return;
          }
      else{
        // console.log("coupon1")
        // console.log(coupon_code_value)
        setCoupon_code_state({is_coupon:true,coupon_code:coupon_code_value})
        // console.log(coupon_code_state,"coupon_code_state")
      const data_obj = {
        plan_id: props_selected_plan_id,
        is_coupon: true,
        coupon_code:coupon_code_value ,
      };
      // console.log(data_obj,"data")
      setLoader(true);
      const resp = await plan_details_and_payment_calc(data_obj);
      const resp_data = resp?.data;
      // console.log(resp, "");
      if (resp.status == 200) {
          setPlans_data(resp_data);
        if (resp_data?.is_coupon_valid == true) {
          setCoupon_code_msg({ status: true, msg: resp_data.msg });
        } else {
          setCoupon_code_msg({ status: false, msg: resp_data.msg });
        }
      }

      setLoader(false);
    }
    } catch (error) {}
  };

  useEffect(() => {
    // console.log("Purchase");
    const handler = async () => {
      try {
        const data_obj = {
          plan_id: props_selected_plan_id,
          is_coupon: false,
          coupon_code: null,
        };
        const resp_data = await plan_details_and_payment_calc(data_obj);
        // console.log(resp_data, "");
        if(resp_data.status==200){
            setPlans_data(resp_data.data)
        }
        else{

        }
      } catch (error) {}
    };
    handler();
  }, []);

  const payment_handler = async (data) => {
    data.preventDefault();
    setBtn_pay_loader(true)
    const seleted_plan_id = props_selected_plan_id;
    const is_coupon=coupon_code_state.is_coupon;
    const coupon_code=coupon_code_state.coupon_code
    
    // alert("buy")
    try {
      // making order and getting generated order id from server
      const response = await payment_order(seleted_plan_id,is_coupon,coupon_code);
      // console.log(response);
      const order_id = response.data.order_id;
      const options_from_server = response.data.options;
      const prefill_from_server = response.data.prefill;
      const transaction_id = options_from_server.receipt;
      // console.log("order_id", order_id);
      var options = {
        // rzp_test_KLDlhy43aOaBVp
        key: "rzp_test_KLDlhy43aOaBVp", // Enter the Key ID generated from the Dashboard
        amount: options_from_server.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Acme Corp", //your business name
        description: "Test Transaction",
        image: "https://example.com/your_logo",
        order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: async (res) => {
          // console.log(res);
          try {
            setShow_modal(true)
            setBtn_pay_loader(false)
            // console.log(res);
            const resp = await payment_validation(
             {res,
              seleted_plan_id,
              transaction_id,    
              seleted_plan_id:props_selected_plan_id,
              is_coupon:coupon_code_state.is_coupon,
              coupon_code:coupon_code_state.coupon_code
              }
            );
            // console.log(resp);
            if(resp.status==200){
              // alert("ok")
             
              setPayment_status_msg({status:true,msg:"Payment is sucessful"})
              setTimeout(() => {
                window.location.pathname="/enrolled-course"
                // navigate("/enrolled-course")
              }, 2000);
            }
            else{
              setBtn_pay_loader(false)
              setPayment_status_msg({status:false,msg:"payment is not validated,please contact Customercare"})
              alert("payment is not validated,please contact Customercare")
            }
          } catch (error) {
            console.log(error);
          }
        },
        prefill: {
          //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
          name: prefill_from_server.name, //your customer's name
          email: prefill_from_server.email,
          contact: prefill_from_server.contact, //Provide the customer's phone number for better conversion rates
        },
        //   notes: {
        //     address: "Razorpay Corporate Office",
        //   },
        //   theme: {
        //     color: "#3399cc",
        //   },
      };
      // console.log(options);
      // 
      var rzp1 = new window.Razorpay(options);
      document.getElementById("rzp_target").onclick = function (e) {
        // console.log(e);
        rzp1.open();
        e.preventDefault();
        // alert("r")
      };
      rzp1.on("payment.submit", (response) => {
        // paymentMethod.current = response.method;
        console.log("payment submitted");
        console.log(response);
      });

      // To get payment id in case of failed transaction.
      rzp1.on("payment.failed", (response) => {
        // paymentId.current = response.error.metadata.payment_id;
        // console.log("payment failed");
        // console.log(response);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
    {/* for payment validation window */}
    {show_modal&& <Modal show_modal={show_modal} setShow_modal={setShow_modal}>
        
        {payment_status_msg.status==null && <div  className="w-100 h-100 btn btn-primary d-flex justify-content-center align-items-center">
             <h3 style={{color:"white"}}>{payment_status_msg.msg}</h3>
             <LoaderComp/>
            </div>}
       {payment_status_msg.status==true && <div className="w-100 h-100 btn btn-primary d-flex justify-content-center align-items-center">
             <h3 className="btn btn-success" style={{color:"white"}}>{payment_status_msg.msg}</h3>
             <br />
             <br/>
             <div>
             <span style={{color:"white"}}>redirecting to enrolled course page</span>
             </div>
            </div>}  

        {payment_status_msg.status==false && <div className="w-100 h-100 btn btn-primary d-flex justify-content-center align-items-center">
             <h3 className=" btn btn-danger" style={{color:"white"}}>{payment_status_msg.msg}</h3>
            </div>}  


           </Modal>}
      <div className="card-body">
        <div className="col-xl-12">
          <div className="widget-stat card">
            {plans_data!=null?
            <div className="card-body p-3">
              <div className="d-flex justify-content-between"><div>Price</div><div>{plans_data?.price_details?.orignal_plan_price}</div></div>
              <div className="d-flex justify-content-between"><div>Discount</div><div>{plans_data?.price_details?.is_discount==false ? "not available":<span>-{plans_data?.price_details?.discount_value}</span>}</div></div>
              <div className="d-flex justify-content-between"><div>Coupon Discount</div><div>{plans_data?.price_details?.is_coupon==false ? "not available":<span>-{plans_data?.price_details?.coupon_discount_value}</span>}</div></div>

              <div className="d-flex justify-content-between"><div>Payable Price</div><div>{plans_data.price_details.final_plan_price}</div></div>
              <br/>
              {/* <span>note-csv amount also m</span> */}

              {/* payment button */}
              <div className="w-100 text-end">
                {/* for loader */}
                {/* {btn_pay_loader==true?
                <button onClick={payment_handler} className="btn btn-success">
               <LoaderComp width={"50px"} height={"20px"}/>
                </button>
                 
                : */}

                {/* since razorpay c=account not exist so it not show */}
                {/* <button id={"rzp_target"} onClick={payment_handler} className="btn btn-success">
                Pay {plans_data.price_details.final_plan_price}</button> */}


                </div>
            </div>
            :
            "data not loaded"
            }
          </div>
        </div>
        <div className="col-xl-12">
          <div className="widget-stat card">
            <div className="card-body p-2">
              <form>
                <div
                  className="
                row"
                >
                  <div className="mb-2 col-xl-12">
                    <input
                      ref={coupon_code_ref}
                      type="text"
                      className="form-control"
                      placeholder="Coupon Code"
                    />
                  </div>
                  <div className="col-xl-12 text-end">
                    <div className="row">
                     <br />
                      <div className="col-12 w-100 text-center">
                       
                      <Msg_display status={coupon_code_msg.status} msg={coupon_code_msg.msg}/>
                   
                   </div>
                   <br />
                      <div className="col-3 w-100 text-center">
                       
                          <button
                            onClick={coupon_apply_button_handler}
                            className="btn btn-primary"
                          >
                            Apply Coupon
                          </button>
                      
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const App = (props) => {
const [plans_data, setPlans_data] = useState(null);
  const navigate = useNavigate();
  // course means plans
  const plan_id = localStorage.getItem("plan_id");
  if (plan_id == null || plan_id == undefined) {
    alert("plese select Again");
    // console.log("plan_id not found");
    navigate("/courses");
  }
  return (
    <>
      <Page_titles name={props.name} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-6">
            <div className="card">
              <div className="card-header">Course details</div>
              <div className="card-body">

               {/* {console.log(plans_data)} */}
              {plans_data!==null?
              <div>{plans_data?.plan_details?.plan_name}</div>
              :
              "data not loaded"
              }
              </div>

            </div>
          </div>
          <div className="col-xl-6">
            <div className="card">
              <div className="card-header">Purchase details</div>
              <Purchase setPlans_data={setPlans_data} plans_data={plans_data} props_selected_plan_id={plan_id} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default App;

{
  /* <div className="col-xl-3  col-sm-6">
            <div className="card overflow-hidden">
              <div
                style={{
                  backgroundColor: "#dce8fa",
                  textTransform: "capitalize",
                  padding: "20px",
                }}
                className="row"
              >
                <div
                  style={{ height: "50px", backgroundColor: "" }}
                  className="d-flex justify-content-center flex-column  align-items-center"
                >
                  <h4>Weeks</h4>
                </div>
                {render_data(arr)}
              </div>
            </div>
          </div> */
}
