import React, { useState } from "react";
import style from "../App.css";

import { Routes, Route, Switch, useParams } from "react-router-dom";
import About from "../pages/public_pages/about_us";
import Home from "../pages/public_pages/home";
import Courses from "../pages/public_pages/courses";
import Login from "../pages/public_pages/login";
import Signup from "../pages/public_pages/signup";
import ForgetPassword from "../pages/public_pages/forgetPassword";
import ResetPassword from "../pages/public_pages/reset_password";

import TopNav from "../components/Public/TopNav";
import Affiliate_link_handler from "../pages/Affiliate_panel_pages/affiliate_link_handler";
import Msg_display from "../components/Msg_display";





function Page404(params) {

  // console.log(params)


  const link = useParams();
  // console.log(link);

  return (
    <div className="d-flex column justify-content-center ">
      <br />

      <br />
      <div>
        <h2>{link["*"]}</h2>
        <Msg_display status={null} msg={"not found"}/>
        {/* <h1>404 not found</h1> */}
      </div>
    </div>
  );
}

function App() {
  // console.log("ok")

  return (
    <div id="main-wrapper">
         <TopNav />
      {/* <Sidebar/> */}
<>
     
      {/* {console.log("till route")} */}
  
        <Routes>
        
          {/* <Route path="" element={<Login name={"Login"} />} /> */}
          {/* <Route path="/" element={<Courses name={"Affiliate-Dashboard"} />} /> */}
          <Route path="/" element={<Login name={"Login"} />} />
          <Route path="/login" element={<Login name={"Login"} />} />
          <Route path="/signup" element={<Signup name={"Signup"} />} />
          <Route path="/forgot-password" element={<ForgetPassword name={"Forgotpassword "} />} />
          <Route path="/reset-password" element={<ResetPassword name={" Reset-Password "} />} />
          <Route path="/Affiliate/:reffereal_code" element={<Affiliate_link_handler name={""} />} />
          <Route path="*" element={<Page404 />} />
          {/* <Route path="*" element={<Page404 />} /> */}
        </Routes>
     
{/* {console.log("last route")} */}
  
    </>
    </div>
  );
}

export default App;
