import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import indian_states from "../../JSON_data/indian_states";
import {  email_otp_verification_service, signup_service } from "../../services/user";
import { login } from "../../store/authSlice";
import { useDispatch } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";
import Msg from "../../components/Msg";
import Msg_display from "../../components/Msg_display";
// import Page_titles from "../../components/Page_titles";

const Email_verification_form = () => {
  const [data_state, setData_state] = useState({
    is_loaded: "",
    data_obj: {},
    msg: "",
    is_render: true,
  });
  const navigate = useNavigate();
  const otp = useRef();
  const signup_handler = async (a) => {
    a.preventDefault();
    setData_state({is_loaded:null,data_obj:{},msg:"",is_render:true});
   const otp_value=otp.current.value
    const resp = await email_otp_verification_service(
      otp_value
    );

    // console.log(resp)
    if (resp.status == 200) {
      setData_state({
        is_loaded: true,
        data_obj: {},
        msg: resp?.data.msg,
        is_render: true,
      });
      setTimeout(() => {
        // redirecting after 2 sec
        navigate("/login")
      }, 2000);
    } else {
      const data = resp.response?.data?.msg;
      setData_state({
        is_loaded: false,
        data_obj: {},
        msg: data,
        is_render: true,
      });
    }
  };


  return (
    <div className="card">
      <div className="card-body">
        <h3 className="text-center">OTP verification</h3>
        <br />
        <div className="basic-form">
          <form>
            <div className="row">
              <div className="mb-3 col-md-12">
                <label className="form-label">OTP</label>
                <input
                  ref={otp}
                  type="text"
                  className="form-control"
                  placeholder="Email OTP"
                />
              </div>
            </div>
            <div className="row">
                <div className="mb-3  w-100 text-center">
                  <div>
                  <Msg_display status={data_state.is_loaded} msg={data_state.msg}/>
                  </div>
                  {data_state.is_loaded == true && (
                    <div>
                      <span>Redirecting to login page</span>
                    </div>
                  )}
                </div>
                <div className="mb-3  w-100 text-center">
                  <button
                    onClick={signup_handler}
                    type="submit"
                    className="btn btn-primary w-50"
                  >
                    Verify
                  </button>
                </div>

                <div className="mb-3 col-md-12 width-100">
                  <br /> 
                </div>
              </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const SignUp_form = ({setShow_otp_form}) => {

  const [data_state, setData_state] = useState({
    is_loaded: "",
    data_obj: {},
    msg: "",
    is_render: true,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const name = useRef();
  const email = useRef();
  const password = useRef();
  const city = useRef();
  const state = useRef();
  const zip_code = useRef();
  const mobile = useRef();
  const reffral_code_ref = useRef();
  const saved_reffral_code = localStorage.getItem("reffral_code");
  // localStorage.removeItem("reffral_code");

  // if reffral code in local storage

  const reffral_field = async () => {};
  useEffect(() => {
    // console.log(reffral_code_ref)
    if (saved_reffral_code != null || saved_reffral_code != "") {
      reffral_code_ref.current.value = saved_reffral_code;
    }
  }, []);

  const signup_handler = async (a) => {
    a.preventDefault();
    setData_state({is_loaded:null,data_obj:{},msg:"",is_render:true});
    const name_value = name.current.value;
    const email_value = email.current.value;
    const password_value = password.current.value;
    const city_value = city.current.value;
    const state_value = state.current.value;
    const zip_code_value = zip_code.current.value;
    const mobile_value = mobile.current.value;
    const referral_by_code_value = reffral_code_ref.current.value;
    const resp = await signup_service(
      name_value,
      password_value,
      email_value,
      mobile_value,
      city_value,
      state_value,
      zip_code_value,
      referral_by_code_value
    );

    // console.log(resp)
    
    if (resp.status >= 200) {
      setData_state({
        is_loaded: true,
        data_obj: {},
        msg: "Signup Successful, please verify your email",
        is_render: true,
      });
      setTimeout(() => {
        // redirecting after 2 sec
        // navigate("/login")
      }, 2000);
      setShow_otp_form(true)
    } else {
      const data = resp.response?.data?.msg;
      setData_state({
        is_loaded: false,
        data_obj: {},
        msg: data,
        is_render: true,
      });
      if(resp.status==400){
        setData_state({
          is_loaded: false,
          data_obj: {},
          msg: "bad request 400",
          is_render: true,
        });
      }
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h3 className="text-center">Signup</h3>
          <br />
          <div className="basic-form">
            <form>
              <div className="row">
                <div className="mb-3 col-md-6">
                  <label className="form-label">Full Name</label>
                  <input
                    ref={name}
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label className="form-label">Email</label>
                  <input
                    ref={email}
                    type="email"
                    className="form-control"
                    placeholder="Email"
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label className="form-label">Password</label>
                  <input
                    ref={password}
                    type="password"
                    className="form-control"
                    placeholder="Password"
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label className="form-label">Mobile no.</label>
                  <input
                    ref={mobile}
                    type="number"
                    className="form-control"
                    placeholder="mobile no."
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label>City</label>
                  <input type="text" ref={city} className="form-control" />
                </div>
                <div className="mb-3 col-md-6">
                  <label className="form-label">State</label>
                  <select
                    ref={state}
                    id="inputState"
                    className="default-select form-control wide"
                  >
                    <option selected="">Choose...</option>
                    {indian_states.map((d) => {
                      return <option key={nanoid()}>{d.name}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col-md-2 w-100">
                  <label className="form-label">Zip</label>
                  <input
                    ref={zip_code}
                    type="number"
                    className="form-control"
                  />
                </div>
                <div className="mb-3 col-md-2 w-100">
                  <label className="form-label">Reffral Code</label>
                  <input
                    ref={reffral_code_ref}
                    onClick={() => {
                      reffral_field();
                    }}
                    type="text"
                    placeholder="reffral code"
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="mb-3  w-100 text-center">
                  <div>
                  <Msg_display status={data_state.is_loaded} msg={data_state.msg}/>
                  </div>
                  {data_state.is_loaded == true && (
                    <div>
                      <span>verify your email</span>
                    </div>
                  )}
                </div>
                <div className="mb-3  w-100 text-center">
                  <button
                    onClick={signup_handler}
                    type="submit"
                    className="btn btn-primary w-50"
                  >
                    Sign Up
                  </button>
                </div>

                <div className="mb-3 col-md-12 width-100">
                  <br />

                  <Link to="/login">
                    <h5 className="text-center w-100 ">
                      already have an Account ?{" "}
                      <span className="text-blue">Login please</span>
                    </h5>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

const Signup = (props) => {
  const[show_otp_form,setShow_otp_form]=useState(false)
  return (
    <>
      {/* <div className="content-body"></div> */}
      {/* <h3 className="text-center">{props.name}</h3> */}
      {/* <div style={{minHeight:"100px"}}></div> */}
      
      <div
        className="d-flex justify-content-center align-items-start "
        style={{
          backgroundColor: "white",
          height: "100vh",
          width: "100vw",
          padding: "50px",
        }}
      >
        <div style={{ width: "70vh" }} className="m-5">
        {show_otp_form==true && <Email_verification_form/> }
          <SignUp_form setShow_otp_form={setShow_otp_form} />
         
        </div>
      </div>
    </>
  );
};

export default Signup;
