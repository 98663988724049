import React from "react";
import Page_titles from "../../components/Page_titles";

const App = (props) => {
  return (
    <div>
      <Page_titles name={props.name} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-4">
            <div className="card">
        
              <div className="card-body">
                <a target="_blank" href="https://forms.gle/bk31fQSp4G8mtF2T8">
              <div style={{width:"100px", height:"50px"}} className=" btn btn-primary d-flex justify-content-center align-items-center">Support </div>
              </a>
                 <br />
                 <br />
              <div><h4>Call Us:+91 9171740035 </h4> </div>
              <div><h4>Call Us:+91 9171740038 </h4> </div>
              </div>
            </div>
          </div>
         
          
        </div>
        <div className="roe">
          <div className="col-xl-6">
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
