import React, { useEffect, useState } from "react";
import Page_titles from "../../components/Page_titles";
import { all_plans_service } from "../../services/data_fetch";
import { payment_order, payment_validation } from "../../services/payments";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


const Plan_render_component=({data_arr,is_user_enrolled})=>{
  const navigate=useNavigate()
  const buy_button_handler=async(e)=>{
    const id=e.target.id
    localStorage.setItem("plan_id",id)
    e.preventDefault();
    navigate("/Purchase");
  }
  // console.log(data_arr)
if(Array.isArray(data_arr)==true){
  return<>{data_arr.map((d,n) => {
    return (
      <div className="col-xl-3  col-lg-6 col-sm-6">
      
        <div className="widget-stat card">
          <div className="card-header text-bg-primary text-white p-3">Name: {d.plan_name}</div>
          <div className="card-body p-3 d-flex flex-column " >
            
        <div className="d-flex justify-content-between"><div>Price:</div> <div>{d.price}</div></div>
        {d.is_discount==true?
                <>
                {is_user_enrolled!=true?
                  <> <div className="d-flex justify-content-between  text-success"><div>Discount:</div><div>{Number(d.discount_percentage)}%</div></div>
                  <div className="d-flex justify-content-between"><div>Final Price:</div> <div>{Number(d.price)*(100-Number(d.discount_percentage))/100}</div></div></>
                :
                <>
                <div className="d-flex justify-content-between"><div>Discount:</div><div>Not Avaliable</div></div>
                  <div className="d-flex justify-content-between"><div>Final Price:</div> <div>{d.price}</div></div></>
                }
               
               </>
        :
        <>
        <div className="d-flex justify-content-between"><div>Discount:</div><div>Not Avaliable</div></div>
        <div className="d-flex justify-content-between"><div>Final Price:</div> <div>{d.price}</div></div>
        </>
        }
        
        
       <br />
            <div class="mb-3 row d-flex ">
              <div class="w-100 text-end" id={n}>
                <button
                  id={d._id}
                  onClick={buy_button_handler}
                  class="btn btn-primary"
                >
                  Buy
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  })}
  </> 
}
else{
  <span> Data not Loaded</span>
}

  
}





function Plans(props) {
  const [plans_data_state, setPlans_data_state] = useState({
    is_loaded: false,
    status:true,
    render: true,
    data_arr: [],
    msg: "",
  });
  const [plans, setplans] = useState([]);
  const navigate=useNavigate()
  const user_details_obj = useSelector((state) => state?.user);
  const user_detail_data_obj=user_details_obj?.user_details
  const is_user_enrolled = user_detail_data_obj?.is_enrolled;
  const plan_enrollment_status=user_details_obj?.enrolled_plan_details?.plan_details
// console.log(user_details_obj)
  useEffect(() => {
    const handler = async (e) => {
      // console.log("handler");
      const user_detail_data_obj=user_details_obj?.user_details
  const is_user_enrolled = user_detail_data_obj?.is_enrolled;
      // console.log(user_detail_data_obj,"user_detail.1")
      if (user_detail_data_obj != undefined) {
        const is_user_enrolled = user_detail_data_obj?.is_enrolled;
        const all_plans_resp = await all_plans_service();
        // console.log(all_plans_resp)
        const all_plans_array = all_plans_resp?.data?.data;
       if(all_plans_resp.status!=200 || Array.isArray(all_plans_array)==false){
        // console.log("recieved data is not array")
        setPlans_data_state({
          is_loaded:false,
          data_arr: [],
          render: false,
          msg: "data not loaded",
        });
       return;
       }
      
        //  checking is user enrooled, if yes then show only higher level plan, if not then show all
        if (is_user_enrolled == true) {
          // console.log("is_user_enrolledd")
          const { enrolled_plan } = user_detail_data_obj;
          const enrolled_plan_price = enrolled_plan.price;
          //  filter all higher plans
         
          const upgrade_plan = all_plans_array.filter((d) => {
            // console.log(d)
            if (Number(d.price) > Number(enrolled_plan_price)) {
              return d;
            }
          });
          //  console.log(upgrade_plan,"else upgrade_plan")
          if (Array.isArray(upgrade_plan) == true) {
            // console.log(upgrade_plan)
            // if no upgrade_plan mean noting to upgrade
            if (upgrade_plan.length == 0) {
              // console.log(upgrade_plan)
              setPlans_data_state({
                is_loaded:false,
                data_arr: upgrade_plan,
                render: false,
                msg: "not avaliable any higher plan to upgrade",
              });
            } else {
              // console.log("else upgrade_plan")
              // if all upgrade_plan to be avaliable to upgrade
              setPlans_data_state({
                data_arr: upgrade_plan,
                is_loaded:true,
                render: true,
                msg: "all aviliable plan to upgragrde/buy",
              });
            }
          } else {
            // since array is not loaded, may be some problem , but show not loaded data, to user, dont show error ,
            setPlans_data_state({
              data_arr: upgrade_plan,
              render: false,
              is_loaded:false,
              msg: "data  not loaded",
            });
          }
        } else {
          // since user is not enrolled so show all dplans
          if (Array.isArray(all_plans_array) == true) {
            setPlans_data_state({ data_arr: all_plans_array, render: true, is_loaded:true, msg: "" });
          } else {
            // since array is not loaded, may be some problem , but show not loaded data, to user, dont show error ,
            setPlans_data_state({
              is_loaded:false,
              data_arr: all_plans_array,
              render: false,
              msg: "data not loaded",
            });
          }
        }
      }
    };
    handler();
  }, [user_details_obj]);


  
 
 

  return (
    <>
      <Page_titles name={props.name} />
      <div className="container-fluid">
        <div className="row">
          {plans_data_state.is_loaded&& <Plan_render_component is_user_enrolled={is_user_enrolled} data_arr={plans_data_state.data_arr}/>}
          {plans_data_state.is_loaded==false && <h5>{plans_data_state.msg}</h5>}

       
        
        </div>
      </div>
    </>
  );
}

export default Plans;
